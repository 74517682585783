import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import "../styles/infoCircle.scss"

export const InfoCircle = ({info}) => {
    return (
        <div className="popoverDiv">
            <Popover overlayStyle={{ width: '300px' }} content={info}>
                <InfoCircleOutlined />
            </Popover>
        </div>
    )
}