import { useEffect, useState } from "react";
import { Divider, Input, Modal } from "antd";
import { changePassword } from "../../../store";
import { validatePassword } from '../../utility/scripts/validate_password.js'
import '../styles/changePassword.scss'

export const ChangePassword = ({ dispatch, open, setOpen, minLength = 8, minLower = 1, minUpper = 1, minNum = 1, minSpecial = 1 }) => {
    const [passwords, setPasswords] = useState({ oldPass: '', newPass: '', reapeatPass: '' })
    const [errors, setErrors] = useState([])

    const clearFields = () => {
        setPasswords({ oldPass: '', newPass: '', reapeatPass: '' })
    }

    const submitPassword = () => {
        let submitErrors = validatePassword(passwords.newPass)

        if (passwords.oldPass.length === 0) {
            submitErrors = submitErrors.concat(['Old Password is missing'])
        }
        if (passwords.newPass.length === 0) {
            submitErrors = submitErrors.concat(['New Password is missing'])
        } else if (passwords.newPass !== passwords.reapeatPass) {
            submitErrors = submitErrors.concat(['Your new passwords do not match'])
        } else if (submitErrors.length === 0) {
            dispatch(changePassword(passwords))
                .then((response) => {
                    submitErrors = response.payload.errors
                    if (submitErrors?.length) {
                        setErrors(submitErrors)
                    } else {
                        clearFields();
                        setOpen(false);
                    }
                })
        }
        setErrors(submitErrors)
    }

    return (
        <Modal
            title="Change Password"
            open={open}
            okText="Submit"
            onOk={() => { setErrors([]); submitPassword(); }}
            onCancel={() => { setOpen(false); setPasswords({ oldPass: '', newPass: '', reapeatPass: '' }); setErrors([]); }}
        >
            <b>Old Password</b>
            <Input.Password
                value={passwords.oldPass}
                onChange={(e) => setPasswords({ ...passwords, oldPass: e.target.value })}
            />
            <b>New Password</b>
            <Input.Password
                value={passwords.newPass}
                onChange={(e) => setPasswords({ ...passwords, newPass: e.target.value })}
            />
            <p className="p">Your new password needs to have the following:</p>
            <ul>
                <li className="li">A minimum of {minLength} characters</li>
                <li className="li">A minimum of {minLower} lowercase characters</li>
                <li className="li">A minimum of {minUpper} uppercase characters</li>
                <li className="li">A minimum of {minNum} numbers</li>
                <li className="li">
                    A minimum of {minSpecial} special characters
                    <ul>
                        <li className="li">{`! # $ % & ( ) * + , - . < = > ? @ ^ _ | ~`}</li>
                    </ul>
                </li>

            </ul>
            <b>Repeat New Password</b>
            <Input.Password
                value={passwords.reapeatPass}
                onChange={(e) => setPasswords({ ...passwords, reapeatPass: e.target.value })}
            />
            {
                errors.length > 0 &&
                <div>
                    <Divider />
                    <ul className="ul">
                        {
                            errors.map((e) => {
                                return <li key={e} className="li error">{e}</li>
                            })
                        }
                    </ul>
                </div>
            }
        </Modal>
    )
}