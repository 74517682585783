import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { verify } from '../../../store';
import { Button, Input, message } from 'antd';
import "../styles/verify.scss"

export const Verify = ({ contactMethod, contact, setVerifying, disableCheck, setVerified, setSave }) => {
    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(0)
    const [action, setAction] = useState({
        contact: '',
        code: ''
    })
    const [sentVerify, setSentVerify] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(s => s - 1);
        }, 1000);

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        setAction({
            ...action,
            contact: contact
        })
    }, [contact])

    useEffect(() => {
        if (seconds < 0) {
            setSeconds(0)
        }
    }, [seconds])

    const startVerification = () => {
        setSeconds(30)
        dispatch(verify({ action: "send", method: contactMethod, ...action }))
            .then((response) => {
                if (response.payload.status === 200) {
                    const infoMessage = `${response.payload.data}.${contactMethod === 'email' ? ' Be sure to check you spam inbox.' : ''}`
                    message.info(infoMessage)
                    setSentVerify(true)
                    setVerifying(true)
                }
            })
        setSentVerify(true)
        setVerifying(true)
    }

    const sendVerificationCode = () => {
        dispatch(verify({ action: 'code', method: contactMethod, ...action }))
            .then((response) => {
                if (response.payload.status === 200) {
                    message.info(`Verified ${contactMethod}`)
                    setVerified(contact)
                    setSave(true)
                    setSentVerify(false)
                    setVerifying(false)
                    setAction({ ...action, code: '' })
                }
            })
        setSentVerify(false)
        setVerifying(false)
    }

    return (
        <div>
            <Button
                className='buttonOffset'
                title={`Verify ${contactMethod}`}
                onClick={startVerification}
                size='small'
                disabled={seconds > 0 || disableCheck}
            >
                Verify
            </Button>
            {
                seconds > 0 ? <p className='pMargin'>{seconds}s</p> : ''
            }
            {
                sentVerify ?
                    <>
                        <Input
                            className='codeInput'
                            value={action.code}
                            placeholder='Verification Code'
                            onChange={(e) => setAction({
                                ...action,
                                code: e.target.value
                            })}
                        />
                        <Button
                            className='codeInput'
                            size='small'
                            onClick={sendVerificationCode}>
                            Confirm
                        </Button>
                    </>
                    :
                    ''

            }
        </div>
    )
}
