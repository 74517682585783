import React, { useState, useEffect, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
import offline from "../../img/offline_marker.png"
import online from "../../img/online_marker.png"
import green_marker from "../../img/marker-icon-green.png"
import red_marker from "../../img/marker-icon-red.png"

const VehiclesMarkerGen = (props) => {
    const [lat, setLat] = useState(51);
    const [long, setLong] = useState(51);
    const [trail, setTrail] = useState(undefined);
    const [seenText, setSeenText] = useState([-1, 'seconds']);
    const [active, setActive] = useState(false);
    const popupRef = useRef(null);

    //Get Vehicle last position and status and update state
    const loadState = () => {
        setLat(props.item.gps_latitude);
        setLong(props.item.gps_longitude);
        const last_seen = props.item.status_datetime;

        let now = new Date();
        const last_date = new Date(last_seen);

        const diff = Math.abs(last_date - now);
        if (diff > props.timeout) {
            setActive(false);
        } else {
            setActive(true);
        }

        if (diff > 1000 * 60 * 60 * 24) {
            setSeenText([Math.floor(diff / 1000 / 60 / 60 / 24), 'days'])
        } else if (diff > 1000 * 60 * 60) {
            setSeenText([Math.floor(diff / 1000 / 60 / 60), 'hours'])
        } else if (diff > 1000 * 60) {
            setSeenText([Math.floor(diff / 1000 / 60), 'minutes'])
        } else if (diff > 1000) {
            setSeenText([Math.floor(diff / 1000), 'seconds'])
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            loadState();
        }, 500);
        return () => clearInterval(intervalId)
    })

    useEffect(() => {
        if (props.selectedUnit === props.unit_id && props.map) {
            popupRef.current.openOn(props.map)
            setTrail(props.item.vehicle_trail)
        }
    }, [props.selectedUnit])

    useEffect(() => {
        if (props.selectedUnit === props.unit_id) {
            setTrail(props.item.vehicle_trail)
        }
    }, [props.item])

    return (
        <div>
            <Marker
                eventHandlers={{
                    click: (e) => {
                        props.handleClick([props.unit_id, props.location].flat())
                    },
                }}
                position={[lat, long]}
                icon={new Icon({ iconUrl: ((active) ? online : offline), popupAnchor: [0, -20], iconAnchor: [11, 35], iconSize: [22, 35] })}
                zIndexOffset={props.unit_id === props.selectedUnit ? 100 : 0}
            >
                <Popup ref={popupRef} autoPan={false}>
                    <div> <b>Vehicle name:</b> {props.item.vehicle_name} <br /></div>
                    <div> <b>Seen:</b> {seenText.join(' ')} ago. <br /></div>
                    {
                        ((active) ? <div><b>Status: </b>ACTIVE</div> :
                            <div><b>Status: </b>NOT ACTIVE</div>)
                    }
                </Popup>
            </Marker>
            {
                trail !== undefined &&
                <div>
                    {
                        trail[1][0] !== null && trail[1][1] !== null && props.selectedUnit === props.unit_id &&
                        <Marker
                            position={[trail[1][0], trail[1][1]]}
                            icon={new Icon({ iconUrl: ((active) ? green_marker : red_marker), popupAnchor: [0, 0], iconAnchor: [12, 12], iconSize: [25, 25] })}
                        />
                    }
                    {
                        trail[1][0] !== null && trail[1][1] !== null && props.selectedUnit === props.unit_id &&
                        <Marker
                            position={[trail[2][0], trail[2][1]]}
                            icon={new Icon({ iconUrl: ((active) ? green_marker : red_marker), popupAnchor: [0, 0], iconAnchor: [10, 10], iconSize: [20, 20] })}
                        />
                    }
                    {
                        trail[1][0] !== null && trail[1][1] !== null && props.selectedUnit === props.unit_id &&
                        <Marker
                            position={[trail[3][0], trail[3][1]]}
                            icon={new Icon({ iconUrl: ((active) ? green_marker : red_marker), popupAnchor: [0, 0], iconAnchor: [7, 7], iconSize: [15, 15] })}
                        />
                    }
                </div>
            }

        </div>
    )
}


export default VehiclesMarkerGen;
