import { useEffect, useState } from "react";
import { Button, Divider, Typography } from "antd";
import { ListElement } from '../ListElement';
import '../../../../../styles/Section.scss';

const { Title } = Typography

export const GroupSection = ({ configuration, hierarchy, selectedIndex, setSelectedIndex, action, setAction, setSave, setManaging, groupOptionsRef, groupListRef, setOpenHieracrchyModal }) => {
    const [newName, setNewName] = useState('');
    const [reorderedConfig, setReorderedConfig] = useState([])
    const validActions = ['add', 'rename', 'remove']

    const notDuplicateName = () => {
        return !configuration.find((config) => config.name === newName)
    }

    const findIndex = (target) => {
        let i = 0;
        for (let config of configuration) {
            if (config.name === target) {
                return i
            }
            i += 1
        }
        return -1
    }

    useEffect(() => {
        let myDict = Object.keys(hierarchy).map((group) => {
            return {
                name: group,
                ...hierarchy[group],
            }
        })
        setReorderedConfig(myDict)
    }, [configuration, hierarchy])

    return (
        <div className="groups">
            <div className="buttons" ref={groupOptionsRef}>
                <Title className="title" level={4}>Options</Title>
                <Button
                    className="button"
                    disabled={action === 'add' || (action !== '' && !validActions.find((a) => a === action))}
                    onClick={() => {
                        setManaging('Groups')
                        setAction('add')
                    }}
                >
                    Create Group
                </Button>
                <Button
                    className="button"
                    disabled={action === 'rename' || (action !== '' && !validActions.find((a) => a === action)) || !configuration.length}
                    onClick={() => {
                        setManaging('Groups')
                        setAction('rename')
                        setSelectedIndex(-1)
                    }}
                >
                    Rename Group
                </Button>
                <Button
                    className="button"
                    disabled={action === 'remove' || (action !== '' && !validActions.find((a) => a === action)) || !configuration.length}
                    onClick={() => {
                        setManaging('Groups')
                        setAction('remove')
                        setSelectedIndex(-1)
                    }}
                >
                    Remove Group
                </Button>
                <Button
                    className="button"
                    disabled={!action || !validActions.find((a) => a === action)}
                    onClick={() => {
                        if (notDuplicateName()) {
                            if (action === 'rename') {
                                setSave([configuration[selectedIndex].name, newName])
                            } else {
                                setSave(newName)
                            }
                            setNewName('')
                        }
                    }}
                >
                    Save
                </Button>
                <Button
                    className="button"
                    disabled={!action || !validActions.find((a) => a === action)}
                    onClick={() => setAction('')}
                >
                    Cancel
                </Button>
            </div>
            <Divider className="divider" type="vertical" />
            <div>
                <Title level={4}>Groups</Title>
                <div className="list" ref={groupListRef}>
                    <ul className="listConfig">
                        {
                            reorderedConfig.map((config, i) => {
                                return <ListElement
                                    key={i}
                                    className={"configItem"}
                                    level={hierarchy[config.name]?.path.length}
                                    index={findIndex(config.name)}
                                    value={config}
                                    action={action}
                                    selected={configuration[selectedIndex]}
                                    setSelected={setSelectedIndex}
                                    name={newName}
                                    setName={setNewName}
                                    saveFunctions={{
                                        notDuplicateName: notDuplicateName,
                                        setSave: setSave,
                                    }}
                                />
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}