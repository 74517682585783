import { Menu } from 'antd'

const Filters = (props, isSpecial) => {
    function getItem(label, key, icon, children, type) {
        return { label, key, icon, children, type };
    }
    function unitsMenu(units) {
        let unitsObj = {}
        for (let unit of units) {
            let list = unitsObj[unit[1]]
            if (list === undefined) {
                unitsObj[unit[1]] = [unit[0]]
            } else {
                unitsObj[unit[1]] = list.concat([unit[0]])
            }
        }
        return Object.entries(unitsObj).map((entry) => { return { key: `g_${entry[0]}`, label: entry[0], type: 'group', children: entry[1].map((name) => getItem(name, `${entry[0]} - ${name}`)) }})
    }
    return (
        [
            getItem('Unit', 'unitFilters', null, unitsMenu(props.units)),
            getItem('Report Type', 'triggerTypeFilters', null,
                [
                    getItem('Automatic', 'Automatic'),
                    getItem('Manual', 'Manual'),
                    getItem('Tablet', 'Tablet'),
                    getItem('Near Miss', 'FA/NM'),
                    isSpecial ? getItem('Debug', 'Debug') : ''
                ]),
            getItem('Hazard Level', 'triggerLevelFilters', null,
                [
                    getItem('No Hazard', 'No Hazard'),
                    getItem('Lights', 'Lights'),
                    getItem('Sirens', 'Sirens'),
                ]),
            getItem('App Version', 'appVersionFilters', null,
                props.appVersions.map(version => getItem(version, version))),
        ]
    );
}

const OptionsMenu = (props) => {
    const items = [
        {
            label: 'Filters',
            key: 'filters',
            children: Filters(props.filters, props.isSpecial),
        }
    ];

    return (
        <div>
            <Menu
                selectedKeys={props.selectedKeys}
                triggerSubMenuAction='click'
                onClick={props.onClick}
                multiple={true}
                items={items}
            />
        </div>
    );
}

export default OptionsMenu