import React from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider, Card, Col, Row, Typography, Menu } from "antd";
import { LOGIN_PAGE } from "../../configuration/paths";
import { getVehicles } from "../../store";
import Vehicle from "./Vehicle";
import Map from "./Map";
import InfoPanel from "./InfoPanel";
import { useEffect } from "react"
import { sortBy } from "lodash";

const { Title } = Typography;


const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = !!useSelector(state => state.app.auth.token)
    const vehicles = useSelector(state => state.app.vehicles)

    const [loading, setLoading] = useState(true);
    const [selectedUnitID, setSelectedUnitID] = useState('');
    const [mapCoord, setMapCoord] = useState([39.0048687, -77.4303391780082]);
    const [filter, setFilter] = useState('All');
    const [label, setLabel] = useState(<p>All</p>);
    const [open, setOpen] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        if (!loggedIn) {
            navigate(LOGIN_PAGE)
        } else {
            dispatch(getVehicles(selectedUnitID))
                .then((response) => {
                    if (response.payload === 200) {
                        setLoading(false)
                    }
                })
            const interval = setInterval(() => {
                dispatch(getVehicles(selectedUnitID))
                    .then((response) => {
                        if (response.payload === 200) {
                            setLoading(false)
                        }
                    })
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [loggedIn, selectedUnitID]);

    useEffect(() => {
        if (!!!selectedUnitID && vehicles?.length > 0) {
            let copy = vehicles?.map(v => v)
            copy?.sort((v1, v2) => v1.serial_number > v2.serial_number ? 1 : -1)
            setMapCoord([copy[0].gps_latitude, copy[0].gps_longitude])
        }
        vehicleList()
        setLoading(vehicles === undefined || vehicles.length === 0);
    }, [vehicles])

    useEffect(() => {
        var label = null;
        switch (filter) {
            case 'All':
                label = <p>All</p>;
                break;
            case 'Online':
                label = <p style={{ color: 'green' }}>Online</p>;
                break;
            case 'Offline':
                label = <p style={{ color: 'red' }}>Offline</p>;
                break;
            default:
                break;
        }
        setLabel(<div>{label}</div>)
    }, [filter])

    const menuItems = () => {
        return [
            {
                label: <p>All</p>,
                key: 'All',
                onclick: () => console.log('all')
            },
            {
                label: <p style={{ color: 'green' }}>Online</p>,
                key: 'Online',
                onclick: () => console.log('on')
            },
            {
                label: <p style={{ color: 'red' }}>Offline</p>,
                key: 'Offline',
                onclick: () => console.log('off')
            },
        ]
    }

    const handleMarkerClick = (item) => {
        setSelectedUnitID(item[0])
        setMapCoord([item[1], item[2]])
    }

    const menuClick = (e) => {
        setFilter(e.key)
        setOpen(false)
    }

    const vehicleList = () => {
        const checkOnlineStatus = (last_seen, status) => {
            let now = new Date();
            const last_date = new Date(last_seen);
            const diff = Math.abs(last_date - now);

            switch (status) {
                case 'All':
                    return true;
                case 'Online':
                    return diff < 60000;
                case 'Offline':
                    return diff >= 60000;
                default:
                    return false
            }
        }
        if (!loading) {
            const list = vehicles?.filter(vehicle => checkOnlineStatus(vehicle.status_datetime, filter))
            if (list.length === 0) {
                return <p>You are not assigned any vehicles or you have not been assigned to a group that has assigned vehicles.</p>
            } else {
                return (sortBy(list, (obj) => obj.serial_number).map(vehicle =>
                    <div
                        key={vehicle.unit_id}
                        onClick={() => {
                            setSelectedUnitID(vehicle.unit_id)
                            setMapCoord([vehicle.gps_latitude, vehicle.gps_longitude])
                        }}>
                        <Vehicle item={vehicle} selectedUnit={selectedUnitID} />
                    </div>
                ))
            }
        } else {
            return <p>Loading...</p>
        }
    }

    return (
        <div className="site-layout-content">
            <Row>
                <Col span={4}>
                    <Card className='cardBorder'>
                        <Card className="vehicleSelector fancyBorder">
                            <Menu
                                onOpenChange={() => setOpen(!open)}
                                onClick={menuClick}
                                openKeys={open ? ['selectedStatus'] : []}
                                mode='inline'
                                items={[{ label: label, key: 'selectedStatus', children: menuItems() }]}
                            />
                            <Divider />
                            {
                                vehicleList()
                            }
                        </Card>
                    </Card>
                </Col>
                <Col span={14}>
                    <Card className='cardBorder map'>
                        <Map
                            handleClick={handleMarkerClick}
                            selectedUnitID={selectedUnitID}
                            mapCoord={mapCoord}
                            vehicles={vehicles}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className='cardBorder'>
                        {
                            selectedUnitID !== '' ?
                                <InfoPanel dispatch={dispatch} item={vehicles.filter(item => item.unit_id.startsWith(selectedUnitID))[0]} /> :
                                <Card className={`fancyBorder`}><Title
                                    level={4}><ExclamationCircleOutlined /> Choose vehicle to display data </Title></Card>
                        }
                    </Card>
                </Col>
            </Row>
        </div>);
}

export default Index;