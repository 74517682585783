import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { getProfile } from '../../store';
import { InteractiveUI } from './components/interactive/InteractiveUI';  // Might make this if I have the time
import { TablesUI } from './components/tables/TablesUI';
import { ACCOUNT_PAGE, HOME_PAGE } from '../../configuration/paths';

export const Management = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const role = useSelector(state => state.app.profile.role !== 'user' && state.app.profile.role !== 'analyst')
    const loggedIn = !!useSelector(state => state.app.auth.token)

    useEffect(() => {
        if (loggedIn) {
            dispatch(getProfile())
        } else {
            navigate(HOME_PAGE)
        }
    }, [loggedIn, dispatch])

    useEffect(() => {
        if (!role) {
            navigate(ACCOUNT_PAGE)
        }
    }, [role])

    return (
        <div>
            {
                loggedIn &&
                <TablesUI />
            }
            {/* {
                isSpecial &&
                <InteractiveUI />
            } */}
        </div>
    )
}