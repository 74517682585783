import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../store';
import ReCAPTCHA from "react-google-recaptcha";
import { Typography } from "antd";
import "./styles/contact.scss";

const { Title } = Typography;

export const ContactForm = () => {
    const dispatch = useDispatch();
    const capRef = useRef(null);
    const [isBot, setIsBot] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const validEmail = (e) => {
        const address = e.split('@')
        if (address.length === 2 && address[0].length > 0 && address[1].length > 0) {
            const domain = address[1].split('.')
            if (domain.length >= 2) {
                for (let part of domain) {
                    if (part.length === 0) {
                        return false
                    }
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const checkVals = () => {
        return !(name === '') && validEmail(email) && !(message === '') && (number.length === 10 || number.length === 0);
    }

    const validNumber = (e) => {
        return (e.length <= 10 && !isNaN(parseInt(e.at(e.length - 1)))) || e === ''
    }

    const formatNumber = (e) => {
        return `(${e.slice(0, 3)})${e.slice(3, 6)}-${e.slice(6)}`
    }

    const handleSubmit = () => {
        if (checkVals()) {
            const contents = {
                message: message,
                name: name,
                email: email
            }
            if (number !== '') {
                contents['number'] = formatNumber(number)
            }
            dispatch(sendEmail(contents))
            setIsBot(!isBot)
            capRef.current.props.grecaptcha.reset()
            setName('');
            setEmail('');
            setNumber('');
            setMessage('');
            setIsEmpty(false)
        } else {
            setIsEmpty(true)
        }

    }

    const onChange = (value) => {
        setIsBot(!isBot)
        // console.log('Captcha value:', value);
    }

    return (
        <>
            <Title style={{ textAlign: 'center', color: "#223747" }}>Contact Us</Title>
            <form onSubmit={e => e.preventDefault()}>
                <div className={`form${window.innerWidth > 750 ? '' : '-mobile'}`}>
                    <input
                        className={`input${isEmpty && name === '' ? '-error' : ''}`}
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type='tel'
                        className={`input${isEmpty && (!validNumber(number) || number.length > 0 || number.length < 10) ? '-error' : ''}`}
                        placeholder="Phone Number"
                        value={number}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (validNumber(value)) {
                                setNumber(value);
                            }
                        }}
                    />
                    <input
                        className={`input${isEmpty && !validEmail(email) ? '-error' : ''}`}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <textarea
                        className={`text${isEmpty && message === '' ? '-error' : ''}`}
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={5}
                        required
                    />
                    <div className='recapthca'>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={capRef}
                            onChange={onChange}
                        />
                    </div>
                    <button
                        className={`button${isBot ? '-disabled' : ''}`}
                        disabled={isBot}
                        onClick={handleSubmit}
                        type='submit'>Submit</button>
                </div>
            </form >
        </>
    );
}