import { Input } from "antd"
import { useEffect } from "react"

export const ListElement = ({ className, level, index, value, action, selected, setSelected, name, setName, saveFunctions }) => {
    useEffect(() => {
        if (value.name === selected?.name) {
            if (action === 'rename') {
                setName(value.name)
            } else {
                setName('')
            }
        }
    }, [action, selected, value.name, setName])

    return (
        <li
            key={value}
            style={{ paddingLeft: `${level * 10}px` }}
            className={action !== 'add' ? action === 'remove' ? `${className} ${className}Delete` : `${className} ${className}Hover` : className}
            onClick={() => action !== 'add' ? setSelected(index) : ''}
        >
            {
                value.name !== undefined ?
                    (value.name === '' && action === 'add') || (action === 'rename' && value.name === selected?.name) ?
                        <Input
                            onChange={(e) => setName(e.target.value)}
                            onPressEnter={() => {
                                if (saveFunctions.notDuplicateName()) {
                                    if (action === 'rename') {
                                        saveFunctions.setSave([selected.name, name])
                                    } else {
                                        saveFunctions.setSave(name)
                                    }
                                    setName('')
                                }
                            }}
                            value={name}
                            placeholder="Enter name"
                            maxLength={50}
                            autoFocus
                        /> :
                        value.name :
                    ''
            }
        </li>
    )
}