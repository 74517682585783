export const HOME_PAGE = "/";
export const PRIVACY_PAGE = HOME_PAGE + "privacy";
export const LOGIN_PAGE = HOME_PAGE + "login";
export const PASSWORD_RESET_REQUEST = HOME_PAGE + "password_reset";
export const PASSWORD_RESET_PAGE = HOME_PAGE + "password_reset/:token?";
export const ACCOUNT_PAGE = HOME_PAGE + "account";
export const ALARM_HISTORY = HOME_PAGE + "alarm_history";
export const ALARM_HISTORY_SUMMARY = HOME_PAGE + "alarm_summary";
export const UPTIME_HISTORY = HOME_PAGE + 'traffic_metrics'
export const SETTINGS_PAGE = HOME_PAGE + 'settings';
export const MANAGEMENT_PAGE = HOME_PAGE + 'management';
export const NEW_ACCOUNT_PAGE = HOME_PAGE + 'create_account/:token?';
export const TEST_PAGE = HOME_PAGE + 'test';
