import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateUserProfile,
    getProfile,
    createTimeBlock,
    updateTimeBlock,
    deleteTimeBlock,
    getTimeBlocks,
    validateUsername,
} from "../../store";
import { Navigate } from "react-router";
import { LOGIN_PAGE } from "../../configuration/paths";
import { Card, Typography, Button, message, notification, Input } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { InfoCircle } from "../utility/components/InfoCircle";
import { Notifications } from "./components/Notifications";
import { TimeBlockMenu } from "./components/TimeBlockMenu";
import { Verify } from "./components/Verify";
import "./styles/settings.scss"
import { ChangePassword } from "./components/ChangePassword";

const { Title } = Typography;

const Index = () => {
    const dispatch = useDispatch();
    const loggedIn = !!useSelector(state => state.app.auth.token);
    const profile = useSelector(state => state.app.profile);
    const timeBlocks = useSelector(state => state.app.timeBlocks);
    const [api, contextHolder] = notification.useNotification();

    const [originalUsername, setOriginalUsername] = useState('');
    const [username, setUsername] = useState('');
    const [validUsername, setValidUsername] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [alertLevelNotifs, setAlertLevelNotifs] = useState({ zero: false, one: false, two: false });
    const [alertTypeNotifs, setAlertTypeNotifs] = useState({ none: false, man: false, tablet: false, auto: false });
    const [receiveNotifs, setReceiveNotifs] = useState({ email: false, sms: false });
    const [intervals, setIntervals] = useState({ daily_email: false, daily_sms: false, weekly_email: false, weekly_sms: false, monthly_email: false, monthly_sms: false })
    const [newTimeBlocks, setNewTimeBlocks] = useState([]);
    const [save, setSave] = useState(false);
    const [settingsError, setSettingsError] = useState(false);
    const [counter, setCounter] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [verifiedEmail, setVerifiedEmail] = useState('');
    const [verifiedSms, setVerifiedSms] = useState('');
    const [openPasswordModal, setOpenPasswordModal] = useState(false);

    const info = {
        email: <p>The email address must have only one (1) "@" symbol and one (1) "." after the "@".<br /><br />Ex: example@email.com<br /><br />You need to verify if you plan on wanting email notifications.</p>,
        disabledNumber: <p>Temporarily disabled until implemented.</p>,
        number: <p>Only enter in numbers, no special characters or letters, and has to be 10 digits. <br /><br />Ex: 1234567890<br /><br />You need to verify if you plan on wanting sms notifications.</p>,
        company: 'Only a group manager can edit this field.',
    };

    const validEmail = () => {
        const splitEmail = email.split("@");
        if (splitEmail.length === 2 && splitEmail[0].length > 0) {
            const domain = splitEmail[1].split(".");
            if (domain.length >= 2) {
                for (let part of domain) {
                    if (part.length === 0) {
                        return false
                    }
                }
                return true;
            }
        }
        return false;
    };

    const checkValidity = () => {
        if (username === '' || email === '' || firstName === '' || lastName === '' || (number.length < 10 && number.length > 0)) {
            setSettingsError(true);
            message.error("Some fields are empty. Please fill them in order to save your settings.");
            return false;
        } else if (!validEmail()) {
            setSettingsError(true);
            message.error("Your email is not in the proper format.");
            return false;
        }

        if (validUsername !== username && username !== originalUsername) {
            setSettingsError(true);
            message.error("Please check to see if the username you have entered is not taken.");
            return false;
        }

        if (receiveNotifs['email'] && email !== verifiedEmail) {
            setSettingsError(true);
            message.error("Your email is not verified. Please verify the email or uncheck the box to recieve email notifications.");
            return false;
        }

        if (receiveNotifs['sms'] && number !== verifiedSms) {
            setSettingsError(true);
            message.error("Your phone number is not verified. Please verify the number or uncheck the box to recieve sms notifications.");
            return false;
        }

        return true
    };

    const toLocalTime = (time) => {
        if (time?.search(":" === 2)) {
            time = time.split(":")
            let newTime = new Date()
            newTime.setHours(parseInt(time[0]))
            newTime.setMinutes(parseInt(time[1]))
            newTime.setSeconds(parseInt(time[2]))
            newTime = new Date(Date.UTC(newTime.getFullYear(), newTime.getMonth(), newTime.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds(), newTime.getMilliseconds()))
            newTime = newTime.toLocaleTimeString()
            newTime = newTime.split(' ')
            newTime = [newTime[0].split(":"), newTime[1]]
            newTime = newTime[1] === "PM" ? `${parseInt(newTime[0][0]) + 12}:${newTime[0][1]}:${newTime[0][2]}` : `${newTime[0][0]}:${newTime[0][1]}:${newTime[0][2]}`
            return newTime
        }

    }

    const toUTC = (time) => {
        const today = new Date()
        time = time.split(":")
        today.setHours(parseInt(time[0]))
        today.setMinutes(parseInt(time[1]))
        today.setSeconds(parseInt(time[2]))
        return `${today.getUTCHours()}:${today.getUTCMinutes()}:${today.getUTCSeconds()}`
    }

    const manageTimeBlocks = () => {
        // I need to reformat the time intervals
        const reformattedBlocks = (reformat) => {
            return reformat.map(tB => {
                return {
                    id: tB.id,
                    day: tB.day,
                    start_time: toUTC(tB.timeInterval[0]),
                    end_time: toUTC(tB.timeInterval[1]),
                    active: tB.enabled
                }
            })
        };

        const updatedBlocks = reformattedBlocks(newTimeBlocks.filter(tB => tB.blockUpdate && !tB.toDelete && !tB.newBlock));
        const newBlocks = reformattedBlocks(newTimeBlocks.filter(tB => tB.newBlock && !tB.toDelete));
        const deletedBlocks = reformattedBlocks(newTimeBlocks.filter(tB => !tB.newBlock && tB.toDelete));

        updatedBlocks.map(uB => {
            dispatch(updateTimeBlock({ time_block: { ...uB }, id: profile.id }));
        })
        newBlocks.map(nB => {
            dispatch(createTimeBlock({ time_block: { ...nB }, id: profile.id }));
        })
        deletedBlocks.map(dB => {
            dispatch(deleteTimeBlock({ time_block: { ...dB }, id: profile.id }));
        })
    };

    const onSave = () => {
        if (checkValidity()) {
            setSettingsError(false);
            manageTimeBlocks();
            var data = {
                userID: profile.id,
                information: {
                    username: validUsername, email: email, first_name: firstName, last_name: lastName, phone: number, company_name: companyName,
                    alert_zero: alertLevelNotifs['zero'], alert_one: alertLevelNotifs['one'], alert_two: alertLevelNotifs['two'],
                    alert_none: alertTypeNotifs['none'], alert_tablet: alertTypeNotifs['tablet'], alert_manual: alertTypeNotifs['man'], alert_automatic: alertTypeNotifs['auto'],
                    email_notification: receiveNotifs['email'], sms_notification: receiveNotifs['sms'],
                    verified_email: email === verifiedEmail, verified_sms: number === verifiedSms,
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    daily_email_notif: intervals['daily_email'], daily_sms_notif: intervals['daily_sms'],
                    weekly_email_notif: intervals['weekly_email'], weekly_sms_notif: intervals['weekly_sms'],
                    monthly_email_notif: intervals['monthly_email'], monthly_sms_notif: intervals['monthly_sms'],
                }
            };

            dispatch(updateUserProfile(data))
                .then((status) => {
                    if (status.payload === 200) {
                        setSave(false)
                    }
                });
        }
    };

    const onInputChange = ({ value, setFunction }) => {
        setSave(true)
        setFunction(value)
    };

    const onReset = () => {
        setSettingsError(false)
        setSave(false)
        initSettings()
        setupTimeBlocks();
    };

    const setupTimeBlocks = () => {
        if (timeBlocks !== undefined) {
            const newBlocks = timeBlocks.map((tB) => {
                setCounter(tB.id)
                setCounter(c => c + 1)
                return {
                    id: tB.id,
                    day: tB.day,
                    timeInterval: [toLocalTime(tB.start_time), toLocalTime(tB.end_time)],
                    enabled: tB.active,
                    edit: false,
                    newBlock: false,
                    blockUpdate: false,
                    toDelete: false,
                }
            });
            setNewTimeBlocks(newBlocks.sort((a, b) => a.id - b.id));
        }
    };

    const initSettings = () => {
        if (profile.username) {
            setOriginalUsername(profile.username)
            setUsername(profile.username)
            setValidUsername(profile.username)
        }
        // profile.username ? setUsername(profile.username) : setUsername('');
        profile.email ? setEmail(profile.email) : setEmail('');
        profile.first_name ? setFirstName(profile.first_name) : setFirstName('');
        profile.last_name ? setLastName(profile.last_name) : setLastName('');
        profile.phone ? setNumber(profile.phone) : setNumber('');
        profile.company_name ? setCompanyName(profile.company_name) : setCompanyName('');
        setAlertLevelNotifs({ zero: profile.alert_zero, one: profile.alert_one, two: profile.alert_two });
        setAlertTypeNotifs({ none: profile.alert_none, tablet: profile.alert_tablet, man: profile.alert_manual, auto: profile.alert_automatic });
        setReceiveNotifs({ email: profile.email_notification, sms: profile.sms_notification });
        setIntervals({
            daily_email: profile.daily_email_notif, daily_sms: profile.daily_sms_notif,
            weekly_email: profile.weekly_email_notif, weekly_sms: profile.weekly_sms_notif,
            monthly_email: profile.monthly_email_notif, monthly_sms: profile.monthly_sms_notif,
        })
        profile.verified_sms ? setVerifiedSms(profile.phone) : setVerifiedSms(undefined);
        profile.verified_email ? setVerifiedEmail(profile.email) : setVerifiedEmail(undefined);
    }

    const checkValidEmail = (
        settingsError &&
        (
            email === '' ||
            !validEmail() ||
            (
                !profile.verified_email &&
                receiveNotifs['email']
            ) ||
            (
                email !== verifiedEmail &&
                profile.verified_email
            )
        )
    );

    const checkValidNumber = (
        settingsError &&
        (
            number.length < 10 ||
            (
                !profile.verified_sms &&
                receiveNotifs['sms']
            ) ||
            (
                number !== verifiedSms &&
                profile.verified_sms
            )
        )
    );

    useEffect(() => {
        setupTimeBlocks();
    }, [timeBlocks]);

    useEffect(() => {
        dispatch(getProfile())
        dispatch(getTimeBlocks(profile.id))
    }, [dispatch]);

    useEffect(() => {
        initSettings()
    }, [profile]);

    useEffect(() => {
        if (username === originalUsername) {
            setValidUsername(username)
        }
    }, [username])

    if (!loggedIn) {
        return <Navigate to={LOGIN_PAGE} />
    }

    return (
        <div className='settings'>
            {contextHolder}
            <Card className='card'>
                <Title>Account Settings</Title>
                <Card className="information">
                    <Title className='title' level={3}>Account Information</Title>
                    <div className="centerItems">
                        <p>Username:</p>
                        <Input
                            className={`input ${settingsError && (username === '' || username !== validUsername) ? "inputError" : ''}`}
                            onChange={e => onInputChange({ value: e.target.value, setFunction: setUsername })}
                            value={username}
                        />
                        <Button
                            className="buttonOffset"
                            size="small"
                            disabled={validUsername === username}
                            onClick={() => {
                                if (username === originalUsername) {
                                    setValidUsername(username)
                                } else {
                                    dispatch(validateUsername({ username: username }))
                                        .then((response) => {
                                            setValidUsername(response.payload.data)
                                            if (response.payload.status === 202) {
                                                api.error({
                                                    message: 'Username Error',
                                                    description: 'That username has already been taken. Please pick another!'
                                                });
                                            }
                                        })
                                }
                            }}
                        >
                            Check
                        </Button>
                    </div>
                    <div className="centerItems">
                        <p>Email:</p>
                        <Input
                            className={`input ${checkValidEmail ? "inputError" : ''}`}
                            onChange={e => onInputChange({ value: e.target.value, setFunction: setEmail })}
                            value={email}
                            disabled={verifying}
                        />
                        <InfoCircle info={info['email']} />
                        <Verify
                            contactMethod={'email'}
                            contact={email}
                            setVerifying={setVerifying}
                            setVerified={setVerifiedEmail}
                            setSave={setSave}
                            disableCheck={email === '' || !validEmail() || email === verifiedEmail}
                        />
                        {
                            email === verifiedEmail ?
                                <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> :
                                <CloseCircleTwoTone twoToneColor={'#ff0000'} />
                        }
                    </div>
                    <Button
                        style={{ marginTop: '20px' }}
                        onClick={() => setOpenPasswordModal(true)}
                    >
                        Change Password
                    </Button>
                </Card>
                <Card className='information'>
                    <Title className='title' level={3}>Personal Information</Title>
                    <div className="centerItems">
                        <p>First Name:</p>
                        <Input
                            className={`input ${settingsError && firstName === '' ? "inputError" : ""}`}
                            onChange={e => onInputChange({ value: e.target.value, setFunction: setFirstName })}
                            value={firstName}
                        />
                        <p className="pOffset">Last Name:</p>
                        <Input
                            className={`input ${settingsError && lastName === '' ? "inputError" : ""}`}
                            onChange={e => onInputChange({ value: e.target.value, setFunction: setLastName })}
                            value={lastName}
                        />
                    </div>
                    <div className="centerItems">
                        <p style={{ color: 'gray' }}>Phone Number:</p>
                        <Input style={{ backgroundColor: 'rgb(200, 200, 200, 0.25)', color: 'lightgray', border: '1px solid lightgray', borderRadius: '6px' }} disabled
                            className={`input ${checkValidNumber ? "inputError" : ""}`}
                            onChange={e => { if (isFinite(e.target.value)) { onInputChange({ value: e.target.value, setFunction: setNumber }) } }}
                            value={number}
                            maxLength={10}
                        />
                        <InfoCircle info={info['disabledNumber']} />
                        <Verify
                            contactMethod={'sms'}
                            contact={number}
                            setVerifying={setVerifying}
                            setVerified={setVerifiedSms}
                            setSave={setSave}
                            disableCheck={number.length !== 10 || number === verifiedSms}
                        />
                        {
                            number === verifiedSms ?
                                <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> :
                                <CloseCircleTwoTone twoToneColor={'#ff0000'} />
                        }
                    </div>
                </Card>
                <Card className='information'>
                    <Title className='title' level={3}>Company Information</Title>
                    <div className="centerItems">
                        <p>Company Name:</p>
                        <Input
                            className="input"
                            onChange={e => onInputChange({ value: e.target.value, setFunction: setCompanyName })}
                            value={companyName}
                            disabled={profile.role === 'analyst' || profile.role === 'user'}
                        />
                        {
                            (profile.role === 'analyst' || profile.role === 'user') && <InfoCircle info={info['company']} />
                        }
                    </div>
                </Card>
                <Card className='information'>
                    <Title className='title' level={3}>Notification Settings</Title>
                    <div className="row">
                        <Notifications
                            alertLevelNotifs={alertLevelNotifs}
                            setAlertLevelNotifs={setAlertLevelNotifs}
                            alertTypeNotifs={alertTypeNotifs}
                            setAlertTypeNotifs={setAlertTypeNotifs}
                            receiveNotifs={receiveNotifs}
                            setReceiveNotifs={setReceiveNotifs}
                            intervals={intervals}
                            setIntervals={setIntervals}
                            setSave={setSave}
                        />
                        {
                            newTimeBlocks ?
                                <TimeBlockMenu
                                    timeBlocks={newTimeBlocks}
                                    setTimeBlocks={setNewTimeBlocks}
                                    setSave={setSave}
                                    counter={counter}
                                    setCounter={setCounter}
                                /> :
                                'Loading Time Blocks'
                        }
                    </div>
                </Card>
                <div className='saveReset'>
                    <Button onClick={onSave} disabled={!save}>Save</Button>
                    <Button onClick={(e) => { e.preventDefault(); onReset(); }} type="text" disabled={!save}><u>Reset</u></Button>
                </div>
            </Card>
            <ChangePassword dispatch={dispatch} open={openPasswordModal} setOpen={setOpenPasswordModal} />
        </div>
    );
};

export default Index;