import { useEffect, useState } from "react";
import { Menu, DatePicker } from "antd";
import '../styles/intervalSelector.scss'

const { RangePicker } = DatePicker;

export const IntervalSelector = ({ intervals, selectedInterval, setInterval, selectedRange, rangePicked }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [openRangePicker, setOpenRangePicker] = useState(false);
    const MONTH_ABBREVIATIONS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const formatRange = (range) => {
        const firstDate = new Date(range[0])
        const secondDate = new Date(range[1])
        return `${MONTH_ABBREVIATIONS[new Date(firstDate).getMonth()]} ${new Date(firstDate).getDate()} - ${MONTH_ABBREVIATIONS[new Date(secondDate).getMonth()]} ${new Date(secondDate).getDate()}`
    }

    return (
        <div className="dateRangeMenu">
            <div className="menu" style={{ border: '1px solid black', width: "90%", margin: '0 auto' }}>
                <Menu
                    style={{backgroundColor: 'unset'}}
                    selectedKeys={selectedInterval}
                    onOpenChange={() => setOpenMenu(!openMenu)}
                    onClick={setInterval}
                    openKeys={openMenu ? ['selectedInterval'] : []}
                    mode="inline"
                    items={[{
                        label: 'Date Ranges',
                        key: 'selectedInterval',
                        children: intervals.map((i) => {
                            if (i === 'Custom') {
                                return {
                                    label: <div
                                        onClick={() => setOpenRangePicker(true)}
                                    >
                                        {i}
                                    </div>,
                                    key: i
                                }
                            }
                            return { label: <div onClick={() => setOpenMenu(false)}>{i}</div>, key: i }
                        })
                    }]}
                />
            </div>
            {
                !openMenu ?
                    <div className="dateRange" style={{ width: '80%', margin: '0 auto', cursor: 'pointer' }} onClick={() => setOpenMenu(true)}>
                        <p style={{ margin: 0, textAlign: 'center', border: '1px solid black', borderTop: 'none' }}>{selectedInterval === 'Custom' ? formatRange(selectedRange) : selectedInterval}</p>
                    </div> : ''
            }
            {
                selectedInterval === 'Custom' && openRangePicker ?
                    <div className='range-picker'>
                        <RangePicker
                            onChange={(moments, dateStrings) => {
                                setOpenMenu(false)
                                setOpenRangePicker(false)
                                rangePicked(moments, dateStrings)
                            }}
                            showTime={{ format: "HH:mm:ss" }}
                            style={{ width: 180 }}
                        />
                    </div> : ''
            }
        </div>
    )
}