import React, { useState } from "react";
import { Button, Input, Popover } from "antd";
import "../styles/daypicker.scss"

const OPTIONS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

const Days = ({
    value,
    setDay,
    options,
    setEditDay,
    setOpen,
}) => {
    return (
        <div>
            <div className="dayDiv">
                <Button className="dayButton" onClick={() => setDay(options[0])} >{options[0]}</Button>
                <Button className="dayButton" onClick={() => setDay(options[1])}>{options[1]}</Button>
                <Button className="dayButton" onClick={() => setDay(options[2])}>{options[2]}</Button>
            </div>
            <div className="dayDiv">
                <Button className="dayButton" onClick={() => setDay(options[3])}>{options[3]}</Button>
                <Button className="dayButton" onClick={() => setDay(options[4])}>{options[4]}</Button>
                <Button className="dayButton" onClick={() => setDay(options[5])}>{options[5]}</Button>
            </div>
            {
                options.length === 8 ?
                    <div className="dayDiv">
                        <Button className="dayButton" onClick={() => setDay(options[6])}>{options[6]}</Button>
                        <Button className="dayButton" onClick={() => setDay(options[7])}>{options[7]}</Button>
                    </div> :
                    <div className="dayDiv">
                        <Button className="singleDayButton" onClick={() => setDay(options[6])}>{options[6]}</Button>
                    </div>
            }
            <hr className="hr" />
            <div className="okButton">
                <Button
                    className="buttonOffset"
                    onClick={() => {
                        if (setEditDay) {
                            setEditDay(false);
                        }
                        setOpen(false);
                    }}
                    disabled={(
                        value.day, value.day === '' ||
                        !options.find(option => option === value.day)
                    )}
                    type="primary"
                    size="small"
                >
                    OK
                </Button>
            </div>
        </div>
    )
}

export const DayTimePicker = ({
    value,
    setDay,
}) => {
    const [open, setOpen] = useState(false)

    return (
        <div
        className="dayTimeInputBox"
        >
            <Popover
                open={open}
                onOpenChange={() => setOpen(true)}
                showArrow={false}
                title={<center><b>Select Day</b></center>}
                placement="bottom"
                content={
                    <Days
                        setDay={setDay}
                        value={{ day: value[0] }}
                        options={OPTIONS.concat('Clear')}
                        setOpen={setOpen}
                    />
                }
                trigger="click"
            >
                <Input
                    placeholder="Day"
                    value={value[0] === 'Clear' ? '' : value[0]}
                />
            </Popover>
        </div>
    )
}

export const DayPicker = ({
    timeBlock,
    updateBlocks,
    setEditDay
}) => {
    const [open, setOpen] = useState(false);

    const setDay = (day) => {
        updateBlocks({
            ...timeBlock,
            day: day
        })
    }

    return (
        <Popover
            open={open}
            onOpenChange={() => setOpen(true)}
            showArrow={false}
            title={<center><b>Select Day</b></center>}
            placement="bottom"
            content={
                <Days
                    value={timeBlock}
                    setDay={setDay}
                    options={OPTIONS.concat('Weekdays')}
                    setEditDay={setEditDay}
                    setOpen={setOpen}
                />
            }
            trigger="click"
        >
            <Input
                className="dayInputBox"
                value={timeBlock.day}
                placeholder="Day"
                onChange={(e) => setDay(e.target.value)}
            />
        </Popover >
    )
}