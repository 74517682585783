import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { ACCOUNT_PAGE, HOME_PAGE } from "../../configuration/paths"
import { getMetricsDashboard } from "../../store"

export const MetricsDashboard = () => {
    const loggedIn = !!useSelector(state => state.app.auth.token);
    const role = useSelector(state => state.app.profile.role)
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [height, setHeight] = useState(0);
    const [dashboard, setDashboard] = useState("");

    useEffect(() => {
        setHeight(document.getElementsByTagName('main')[0].offsetHeight - 6)
        const handleReize = () => {
            setHeight(document.getElementsByTagName('main')[0].offsetHeight)
        }
        window.addEventListener('resize', handleReize)
        return () => window.removeEventListener('resize', handleReize)
    }, [])

    useEffect(() => {
        dispatch(getMetricsDashboard())
            .then((response) => {
                setDashboard(response.payload.data.url)
            })
    }, [dispatch])

    useEffect(() => {
        if (!loggedIn) {
            nav(HOME_PAGE)
        } else if (role === 'user') {
            nav(ACCOUNT_PAGE)
        }
    }, [loggedIn, role, nav])

    return (
        dashboard ?
            <iframe title="metrics" style={{ border: 'none', margin: '0px' }} src={dashboard} width="100%" height={height}></iframe> :
            <p
                style={{
                    border: "1px solid black",
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 4px',
                    width: 'fit-content',
                    padding: '25px',
                    margin: "auto"
                }}
            >
                You metrics dashboard is still being implemented. Check back later to see if it is done!
            </p>
    )
}