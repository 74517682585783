import React, { useEffect, useState } from "react";
import { Button, Checkbox, Popover, TimePicker } from "antd";
import { CheckOutlined, EditOutlined, CalendarOutlined, ClockCircleOutlined, EllipsisOutlined, DeleteOutlined } from "@ant-design/icons";
import { DayPicker } from "./DayPicker";
import "../styles/timeblock.scss"

const EditTimeBlock = ({
    timeBlock,
    updateBlocks,
    setEditBlock,
    setNewTimeBlock,
    setSave
}) => {
    const [editDay, setEditDay] = useState(false);
    const [editTime, setEditTime] = useState(false);

    // Need to only have either the "Edit Day" or "Edit Time" open for space constraints
    return (
        <div className="editTimeblocks">
            {
                editDay ?
                    <DayPicker
                        timeBlock={timeBlock}
                        updateBlocks={updateBlocks}
                        setEditDay={setEditDay}
                    /> :
                    <Button
                        className="buttonOffset"
                        icon={<CalendarOutlined />}
                        title={timeBlock.day}
                        onClick={() => {
                            setEditTime(false);
                            setEditDay(true);
                        }}>
                        Edit Day
                    </Button>
            }
            {
                editTime ?
                    <TimePicker.RangePicker
                        className="timepicker"
                        onChange={(date, dateStrings) => {
                            updateBlocks({
                                ...timeBlock,
                                timeInterval: [dateStrings[0], dateStrings[1]]
                            })
                            setEditTime(false);
                        }}
                    /> :
                    <Button
                        className="buttonOffset"
                        icon={<ClockCircleOutlined />}
                        title={
                            timeBlock.timeInterval ?
                                `${timeBlock.timeInterval[0]} - ${timeBlock.timeInterval[1]}` :
                                ''
                        }
                        onClick={() => {
                            setEditDay(false);
                            setEditTime(true);
                        }}
                    >
                        Edit Time
                    </Button>
            }
            <Button
                icon={<CheckOutlined />}
                title="Confirm Changes"
                onClick={() => {
                    if (!timeBlock.newBlock) {
                        updateBlocks({
                            ...timeBlock,
                            edit: false,
                            // blockUpdate: true
                        })
                    }
                    setEditBlock(false);
                    setNewTimeBlock(false);
                    setSave(true)
                }}
            />
        </div>
    );
}

export const TimeBlock = ({
    newTimeBlocks,
    setNewTimeBlocks,
    setNewTimeBlock,
    timeBlock,
    deleteTimeBlocks,
    setSave
}) => {
    const [editBlock, setEditBlock] = useState(true);
    const [curTB, setCurTB] = useState({});
    const [text, setText] = useState('')
    const [classname, setClassname] = useState({})

    useEffect(() => {
        setCurTB(timeBlock)
    }, [timeBlock])

    useEffect(() => {
        setClassname(curTB.toDelete ? "pDelete" : "p")
    }, [curTB])

    useEffect(() => {
        if (curTB.timeInterval) {
            setText(
                <p className={classname}>
                    {curTB.day}{curTB.day === 'Weekdays' ? '' : 's'} between {curTB.timeInterval[0]} - {curTB.timeInterval[1]}
                </p>
            );
        }
    }, [classname, curTB])

    useEffect(() => {
        setEditBlock(curTB.edit)
    }, [curTB.edit, deleteTimeBlocks]);

    const updateBlocks = (block) => {
        setCurTB(block)
        const newBlocks = newTimeBlocks.map((tB) => {
            if (block.id === tB.id) {
                return { ...block, blockUpdate: true }
            } else {
                return { ...tB }
            }
        })
        setNewTimeBlocks(newBlocks)
    }

    const content = (
        <div className="optionsItems">
            <Button
                icon={<EditOutlined />}
                type="text"
                onClick={() => setEditBlock(true)}
            >
                Edit
            </Button>
            <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => {
                    const newBlocks = newTimeBlocks.map((tB) => {
                        if (tB.id === curTB.id) {
                            const newTB = { ...tB, toDelete: !tB.toDelete }
                            setCurTB(newTB);
                            return newTB
                        } else {
                            return tB
                        }
                    });
                    setSave(true);
                    setNewTimeBlocks(newBlocks)
                }}
            >
                Delete
            </Button>
            <Button type="text" onClick={() => { setSave(true); updateBlocks({...timeBlock, enabled: !timeBlock.enabled}) }}>
                {
                    timeBlock.enabled ?
                        "Disable" :
                        "Enable"
                }
            </Button>
        </div>
    )

    return (
        <div className="">
            {
                editBlock ?
                    <EditTimeBlock
                        timeBlock={curTB}
                        updateBlocks={updateBlocks}
                        setNewTimeBlock={setNewTimeBlock}
                        setEditBlock={setEditBlock}
                        setSave={setSave}
                    /> :
                    <div className="timeblockDiv">
                        {
                            curTB.day === '' || curTB.timeInterval[0] === '' || curTB.timeInterval[1] === '' ?
                                <p className={classname}>Please select "Edit" to fill out your Time Block.</p> :
                                timeBlock.enabled ? text : <s>{text}</s>
                        }
                        <Popover placement="bottom" content={content} trigger="click">
                            <Button className="optionsMenu" icon={<EllipsisOutlined />} />
                        </Popover>
                    </div>
            }
        </div>
    );
}