import { useEffect, useState } from "react";
import { AutoComplete, Button, Divider, Typography } from "antd";

const { Title } = Typography;

export const VehicleSection = ({ configuration, selectedIndex, unassignedVehicles, tempConfig, setTempConfig, action, setAction, setSave, setManaging, vehicleOptionsRef }) => {
    const [validAction, setValidAction] = useState(action === 'addSubVehicle' || action === 'removeSubVehicle');
    const [visibleVehicles, setVisibleVehicles] = useState([]);
    const [wantedVehicle, setWantedVehicle] = useState('');

    const getAvailableVehicles = (assignedVehicles) => {
        let leftOverVehicles = []
        for (let vehicle of unassignedVehicles) {
            if (!assignedVehicles.find((val) => val === vehicle)) {
                leftOverVehicles = [...leftOverVehicles, { value: vehicle, label: vehicle }]
            }
        }
        return leftOverVehicles.length ? leftOverVehicles : [{ value: 'No vehicles left' }]
    }

    const getSubgroup = (path) => {
        let group = configuration
        for (let index of path) {
            if (index === -1) {
                continue;
            }
            group = configuration[index]
        }
        return group
    }

    const getSubgroupVehicles = (group) => {
        let vehicles = []
        if (group.subgroups.length === 0) {
            return vehicles
        } else {
            for (let subgroup of group.subgroups) {
                vehicles = vehicles.concat(subgroup.vehicles.map((vehicle) => { return { group: subgroup.name, vehicle: vehicle } }))
                vehicles = vehicles.concat(getSubgroupVehicles(subgroup))
            }
            return vehicles
        }
    }

    useEffect(() => {
        setValidAction(action === 'addSubVehicle' || action === 'removeSubVehicle')
    }, [action])

    useEffect(() => {
        if (tempConfig?.subgroups) {
            setVisibleVehicles(getSubgroupVehicles(tempConfig))
        }
    }, [tempConfig])

    return (
        <div className="groups">
            <div className="buttons" ref={vehicleOptionsRef}>
                <Title className="title" level={4}>Options</Title>
                <Button
                    className="button"
                    disabled={action || selectedIndex === -1}
                    onClick={() => {
                        setManaging('Groups')
                        setAction('addSubVehicle')
                        setTempConfig({
                            ...configuration[selectedIndex],
                            vehicles: [...configuration[selectedIndex].vehicles, '']
                        })
                    }}
                >
                    Assign Vehicle
                </Button>
                <Button
                    className="button"
                    disabled={action || selectedIndex === -1 || !configuration[selectedIndex].vehicles.length}
                    onClick={() => {
                        setManaging('Groups')
                        setAction('removeSubVehicle')
                    }}
                >
                    Remove Vehicle
                </Button>
                <Button
                    className="button"
                    disabled={!validAction}
                    onClick={() => {
                        setTempConfig(configuration[selectedIndex])
                        setAction('')
                    }}
                >
                    Cancel
                </Button>
            </div>
            <Divider className="divider" type="vertical" />
            <div>
                <Title level={4}>Vehicles</Title>
                <div className="list">
                    {
                        tempConfig.vehicles?.length || visibleVehicles.length ?
                            <ul className="listConfig">
                                {
                                    tempConfig.vehicles?.map((vehicle, i) => {
                                        return <li
                                            className={`configItem ${action === 'removeSubVehicle' ? 'configItemDelete' : ''}`}
                                            onClick={() => {
                                                if (action === 'removeSubVehicle') {
                                                    setSave([configuration[selectedIndex].name, vehicle])
                                                }
                                            }}
                                            key={`${i}-${vehicle}`}
                                        >
                                            {
                                                vehicle === '' ?
                                                    <AutoComplete
                                                        style={{ width: 200, }}
                                                        allowClear
                                                        backfill
                                                        options={getAvailableVehicles(tempConfig.vehicles)}
                                                        placeholder="Select vehicle"
                                                        value={wantedVehicle}
                                                        onChange={(value, option) => {
                                                            setWantedVehicle(value)
                                                        }}
                                                        filterOption={(inputVal, option) =>
                                                            option.value.toUpperCase().indexOf(inputVal.toUpperCase()) !== -1
                                                        }
                                                        onSelect={(value, option) => {
                                                            if (value !== 'No vehicles left') {
                                                                setSave([configuration[selectedIndex].name, value])
                                                            }
                                                            setWantedVehicle('')
                                                        }}
                                                    /> :
                                                    vehicle
                                            }
                                        </li>
                                    })
                                }
                                {
                                    !action ?
                                    visibleVehicles.map((vehicle) => {
                                        return <li
                                            className="configItem"
                                            style={{ color: 'gray' }}
                                            title={`Assigned to: ${vehicle.group}`}
                                            key={`${vehicle.vehicle}`}
                                        >
                                            {vehicle.vehicle}
                                        </li>
                                    }) : ''
                                }
                            </ul> :
                            <p className="subgroupText">There are currently no vehicles assigned to this group.</p>
                    }
                </div>
            </div>
        </div>
    )
}