import { Button, Input, Select } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

export const CreateUserModal = ({ usernameInfo }) => {
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const options = [
        {
            value: 'analyst',
            label: 'Group Analyst'
        },
        {
            value: 'user',
            label: 'Group User'
        }
    ]

    const isEmailValid = (email) => {
        const splitAddress = email.split('@')
        if (splitAddress.length === 2 && splitAddress[0].length > 0 && splitAddress[1].length > 0) {
            const domainSplit = splitAddress[1].split('.')
            if (domainSplit.length > 1) {
                for (let part of domainSplit) {
                    if (part.length === 0) {
                        return false
                    }
                }
                return true
            }
        }
        return false
    }

    useEffect(() => {
        setEmail('')
    }, [])

    useEffect(() => {
        setValidEmail(isEmailValid(email) && usernameInfo.validUsername)
    }, [email, usernameInfo.validUsername])

    return (
        <div>
            <h3 style={{ marginBottom: '10px' }}><UsergroupAddOutlined /> Create New User</h3>
            <div style={{ margin: '5px 0px' }}>
                <p>Enter in the username for the new user:</p>
                <Input
                    style={{ width: '50%' }}
                    maxLength={150}
                    onChange={(e) => {
                        usernameInfo.setUsername(e.target.value)
                    }}
                    onPressEnter={() => {
                        usernameInfo.checkUsername()
                    }}
                />
                <Button
                    style={{ marginLeft: '10px' }}
                    disabled={usernameInfo.username === usernameInfo.usernameError || usernameInfo.username === '' || usernameInfo.username === usernameInfo.validUsername}
                    onClick={() => {
                        usernameInfo.checkUsername()
                    }}
                >
                    Check
                </Button>
            </div>
            <div>
                <p>Enter in the email for the new user:</p>
                <Input
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
            </div>
            <div
                style={{ display: 'flex', flexDirection: 'row' }}
            >
                <p style={{ margin: 'auto 0px', marginRight: '3px' }}>Role:</p>
                <Select
                    style={{ width: "151px", margin: '5px 0px' }}
                    showSearch
                    placeholder="Select a role"
                    defaultValue={'user'}
                    options={options}
                    onChange={(e) => usernameInfo.setRole(e)}
                />
            </div>
            <Button
                style={{ marginLeft: '' }}
                disabled={!validEmail}
                onClick={() => {
                    usernameInfo.setEmail(email)
                }}
            >
                Send
            </Button>
            {
                usernameInfo.usernameError === usernameInfo.username && usernameInfo.username !== '' && <p style={{ marginTop: '10px', color: 'red' }}>That username is already taken!</p>
            }
        </div>
    )
}