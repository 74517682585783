import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FloatButton, Input, Modal, Tour, Typography, message, notification } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { addGroup, addSubUser, addSubVehicle, addSubgroup, addUserVehicle, changeUserRole, createNewUser, getAllConfigs, getMainGroup, getUserLicencesLeft, removeGroup, removeSubUser, removeSubVehicle, removeSubgroup, removeUser, removeUserVehicle, renameGroup, renameVehicle, validateUsername } from "../../../../store";
import '../../styles/TablesUI.scss';
import { GroupConfig } from "./configurations/groups/GroupConfig";
import { UsersConfig } from "./configurations/users/UsersConfig";
import { VehiclesConfig } from "./configurations/vehicles/VehiclesConfig";
import { Hieracrchy } from "./configurations/Hierarchy";

const managementFunctions = {
    Groups: {
        add: addGroup,
        rename: renameGroup,
        remove: removeGroup,
        addSubgroup: addSubgroup,
        removeSubgroup: removeSubgroup,
        addSubUser: addSubUser,
        removeSubUser: removeSubUser,
        addSubVehicle: addSubVehicle,
        removeSubVehicle: removeSubVehicle,
    },
    Users: {
        createUser: createNewUser,
        removeUser: removeUser,
        changeRole: changeUserRole,
        addUserVehicle: addUserVehicle,
        removeUserVehicle: removeUserVehicle,
    },
    Vehicles: {
        renameVehicle: renameVehicle,
    }
}

const Context = createContext({ name: 'Confirm Delete User', });
const { Title } = Typography;

export const TablesUI = () => {
    const dispatch = useDispatch();
    const editorRole = useSelector(state => state.app.profile.role)
    const [managing, setManaging] = useState('Groups');
    const [configurations, setConfigurations] = useState({
        Groups: [],
        Users: [],
        Vehicles: []
    })
    const [mainGroup, setMainGroup] = useState('');
    const [userLicences, setUserLicences] = useState(0);
    const [action, setAction] = useState('');
    const [save, setSave] = useState('');
    const [openHieracrchyModal, setOpenHieracrchyModal] = useState(false);
    // For user creatation
    const [username, setUsername] = useState('');
    const [validUsername, setValidUsername] = useState('');
    const [role, setRole] = useState('user')
    const [email, setEmail] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const contextValue = useMemo(() => ({ name: 'save' }), [],);
    // For the Tour
    const [page, setPage] = useState(0);
    const groupOptions = useRef(null);
    const groupsList = useRef(null);
    const subgroupOptions = useRef(null);
    const userOptionsRef = useRef(null);
    const vehicleOptionsRef = useRef(null);
    const createUserRef = useRef(null);
    const removeUserRef = useRef(null);
    const groupRefs = {
        groupOptionsRef: groupOptions,
        groupListRef: groupsList,
        subgroupOptionsRef: subgroupOptions,
        userOptionsRef: userOptionsRef,
        vehicleOptionsRef: vehicleOptionsRef,
    }
    const userRefs = {
        createUserRef: createUserRef,
        removeUserRef: removeUserRef,
    }
    const [openTour, setOpenTour] = useState(false);
    const tourStep = [
        {
            title: 'Group Management Options',
            description: 'Select an option to change group settings. When making a new group, or renaming one, enter a group name and click "Save" to update it.',
            placement: 'right',
            target: () => groupOptions.current,
        },
        {
            title: 'Groups List',
            description: 'Here you can find a list of all the groups that you have created. From this list, you can select a group to view and configure its settings.',
            placement: 'right',
            target: () => groupsList.current,
        },
        {
            title: 'Subgroup Management Options',
            description: 'Select an option to change the assigned subgroups of the selected group.',
            placement: 'right',
            target: () => subgroupOptions.current,
        },
        {
            title: 'Group Assigned User Mangement Options',
            description: 'Select on option to change who is assigned to the selected group.',
            placement: 'right',
            target: () => userOptionsRef.current,
        },
        {
            title: 'Group Assigned Vehicle Mangement Options',
            description: 'Select on option to change what vehicles are assigned to the selected group.',
            placement: 'right',
            target: () => vehicleOptionsRef.current,
        },
        {
            title: 'Creating a New User',
            description: <ul>
                <li style={{ margin: '5px 15px', marginRight: 0 }}>When creating a new user, you will be entering a username for the user to log in with (they are able to change their username at any time).</li>
                <li style={{ margin: '5px 15px', marginRight: 0 }}>You will also be able to assign manage the new user right away, just like any other user.</li>
                <li style={{ margin: '5px 15px', marginRight: 0 }}>Once a new user has been created, you will be getting an email with instructions for how to get the new account set up.</li>
                <li style={{ margin: '5px 15px', marginRight: 0 }}>To see how many licences you have left, just hover over this button.</li>
            </ul>,
            placement: 'top',
            target: () => createUserRef.current,
        },
        {
            title: 'Remove a created user',
            description: 'You are only able to remove users that are not labeled as a group manager. You can hover over a user to see if they are a group manager.',
            placement: 'top',
            target: () => removeUserRef.current,
        }
    ]

    const checkUsername = () => {
        dispatch(validateUsername({ username: username }))
            .then((response) => {
                if (response.payload.status === 200) {
                    setValidUsername(response.payload.data)
                } else if (response.payload.status === 202) {
                    setUsernameError(username)
                }
            })
    }

    const resetSaveAction = () => {
        setAction('')
        setSave('')
    }

    const openConfirmDeleteUser = (placement, user) => {
        api.info({
            message: 'Delete User',
            description: <Context.Consumer>{({ name }) => <div>
                <p>Are you sure that you want to delete <b>{user.user}</b>?</p>
                <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        api.destroy()
                        resetSaveAction()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    danger
                    onClick={() => {
                        updateConfiguration(user)
                        api.destroy()
                    }}
                >
                    Delete
                </Button>
            </div>
            }
            </Context.Consumer>,
            placement,
            duration: 0,
            onClose: resetSaveAction
        })
    }

    const getLicences = () => {
        dispatch(getUserLicencesLeft())
            .then((response) => {
                if (response.payload.status === 200) {
                    setUserLicences(response.payload.data)
                }
            })
    }

    const updateConfiguration = (saveData) => {
        dispatch(managementFunctions[managing][action](saveData))
            .then((response) => {
                if (response.payload.status === 200) {
                    if (managing === 'Users') {
                        getLicences()
                    }
                    if (action === 'createUser') {
                        message.info(<>An email has been sent to <b>{validUsername}</b> with directions to finish setting up their new account.</>)
                        setValidUsername('')
                        setEmail('')
                    }
                    if (action === 'renameVehicle') {
                        response = response.payload
                        setConfigurations({
                            Groups: [...response.data.groups].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1),
                            Users: [...response.data.users].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1),
                            Vehicles: [...response.data.vehicles].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1)
                        })
                    } else {
                        let newConfig = { ...configurations }
                        newConfig[managing] = [...response.payload.data].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1)
                        setConfigurations(newConfig)
                    }
                }
                setSave('')
                setAction('')
            })
    }

    useEffect(() => {
        dispatch(getAllConfigs())
            .then((response) => {
                response = response.payload
                if (response.status === 200) {
                    setConfigurations({
                        Groups: [...response.data.groups].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1),
                        Users: [...response.data.users].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1),
                        Vehicles: [...response.data.vehicles].sort((v1, v2) => v1.name.toLowerCase() > v2.name.toLowerCase() ? 1 : -1)
                    })
                }
            })

        dispatch(getMainGroup())
            .then((response) => {
                if (response.payload.status === 200) {
                    setMainGroup(response.payload.data)
                }
            })

        getLicences()
    }, [dispatch])

    useEffect(() => {
        if (configurations[managing].length > 0 && configurations[managing][configurations[managing].length - 1].name === '') {
            let newConfigs = { ...configurations }
            newConfigs[managing] = configurations[managing].slice(0, configurations[managing].length - 1)
            setConfigurations(newConfigs)
        }

        if (action === 'add') {
            let newConfigs = { ...configurations }
            newConfigs[managing] = [...configurations[managing], { name: '', subgroups: [], users: [], vehicles: [] }]
            setConfigurations(newConfigs)
        }
    }, [action])

    useEffect(() => {
        if (email) {
            setSave(validUsername)
        }
    }, [email, validUsername])

    useEffect(() => {
        if (save) {
            let saveData = {}
            if (typeof save === 'object') {
                saveData[managing.slice(0, managing.length - 1).toLowerCase()] = save[0]
                saveData['other'] = save[1]
            } else {
                saveData[managing.slice(0, managing.length - 1).toLowerCase()] = save
            }
            if (action === 'removeUser') {
                openConfirmDeleteUser('topRight', saveData)
            } else if (action === 'createUser') {
                updateConfiguration({ ...saveData, email: email, role: role })
            } else {
                updateConfiguration(saveData)
            }
        }
    }, [save])

    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <div className="workingZone">
                <Title style={{ textAlign: 'center', textDecoration: 'underline' }}>{mainGroup} Management Settings</Title>
                <div className="configs">
                    <div>
                        <GroupConfig
                            mainGroup={mainGroup}
                            configuration={configurations}
                            action={action}
                            setAction={setAction}
                            setSave={setSave}
                            setManaging={setManaging}
                            refs={groupRefs}
                            setOpenHieracrchyModal={setOpenHieracrchyModal}
                        />
                        <UsersConfig
                            editorRole={editorRole}
                            configuration={configurations}
                            action={action}
                            setAction={setAction}
                            setSave={setSave}
                            setManaging={setManaging}
                            userLicences={userLicences}
                            usernameInfo={{
                                checkUsername: checkUsername,
                                username: username,
                                setEmail: setEmail,
                                usernameError: usernameError,
                                setRole: setRole,
                                setUsername: setUsername,
                                validUsername: validUsername,
                                setValidUsername: setValidUsername,
                            }}
                            refs={userRefs}
                        />
                        <VehiclesConfig
                            configuration={configurations}
                            action={action}
                            setAction={setAction}
                            setSave={setSave}
                            setManaging={setManaging}
                        />
                    </div>
                </div>
            </div>
            <FloatButton icon={<QuestionCircleOutlined />} tooltip={"Help"} onClick={() => { window.scrollTo(0, 50); setOpenTour(true) }} />
            <Tour
                open={openTour}
                onClose={() => {
                    setPage(0)
                    setOpenTour(false)
                }}
                steps={tourStep}
                onChange={(number) => {
                    setPage(number)
                }}
                current={page}
                indicatorsRender={(current, total) => (
                    <>
                        {<Input
                            style={{ width: '50px' }}
                            value={Number.isFinite(page) ? page + 1 : page}
                            onChange={(e) => {
                                const pageNum = parseInt(e.target.value)
                                if ((pageNum <= total && pageNum > 0) || isNaN(pageNum)) {
                                    if (isNaN(pageNum)) {
                                        setPage('')
                                    } else {
                                        setPage(pageNum - 1)
                                    }
                                }
                            }}
                        />} / {total}
                    </>
                )} />
            <Modal
                open={openHieracrchyModal}
                footer={null}
                onCancel={() => setOpenHieracrchyModal(false)}
                title={`Group Hierarchy of ${mainGroup}`}
            >
                <Hieracrchy mainGroup={mainGroup} groupConfigurations={configurations.Groups} />
            </Modal>
        </Context.Provider>
    )
}