export const propsData = {
    frame8: {
        machineLearningAi: "Dual sensor modalities",
        deepNeuralNetworks: "ROADZ sensors use both radar and computer vision.",
        num: "01",
    },
    frame9: {
        num: "02",
        deepNeuralNetworks:
            "Two stereo pairs, four cameras in total, create a hyperfocal area where all objects are in focus from 3 to 300 meters away.",
        machineLearningAi: "Hyperfocal Depth",
    },
    frame10: {
        machineLearningAi: "Sensor Fusion",
        deepNeuralNetworks:
            "The visual data is calibrated to the\nradar data creating a unified frame of information every 33 ms.",
        num: "03",
    },
    frame11: {
        num: "04",
        machineLearningAi: "Machine learning & AI",
        deepNeuralNetworks:
            "Deep neural networks process the data in four independent, embedded, VPUs for real-time lane detection, object detection, and depth perception.",
    },
    frame12: {
        machineLearningAi: "Dynamic Protected Area",
        deepNeuralNetworks:
            "Since the protected area is redefined with each frame, ROADZ Armor is effective on-the-move. This benefits mobile work zones as well as static ones.",
        num: "05",
    },
    frame13: {
        num: "06",
        machineLearningAi: "Threat classification",
        deepNeuralNetworks:
            "Threat levels are determined by location of the approaching vehicle (in or out of work zone), velocity, and distance. Lights and sirens are triggered independently.",
    },
};