import { useState } from "react"
import { Button, Form, Input, notification, message } from "antd"
import { validateToken, regenToken as rt } from "../../../store"
import { HOME_PAGE } from "../../../configuration/paths";

export const SubmitToken = ({ dispatch, form, navigate }) => {
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [api, contextHolder] = notification.useNotification();

    const regenToken = () => {
        api.info({
            message: 'Token has expired!',
            description: <div>
                <p>Would you like to generate a new token?</p>
                <div>
                    <Button
                        type="primary"
                        onClick={() => {
                            dispatch(rt({ token: token }))
                                .then((response) => {
                                    if (response.payload.status === 200) {
                                        message.info('A new token has been emailed to the original user who created your account.')
                                    }
                                })
                            api.destroy()
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        style={{ margin: '0px 5px' }}
                        onClick={() => {
                            api.destroy()
                            navigate(HOME_PAGE)
                        }}
                    >
                        No
                    </Button>
                </div>
            </div>,
            duration: 0,
        })
    }

    return (
        <Form
            form={form}
        >
            {contextHolder}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ fontWeight: 'bold' }}>Enter the code that you got from the email:</p>
                <Input
                    style={{ width: '70%', margin: '10px 0' }}
                    onChange={(e) => setToken(e.target.value)}
                    value={token}
                    placeholder="Please enter token here!"
                />
                <Button
                    style={{ width: 'fit-content' }}
                    onClick={() => {
                        dispatch(validateToken({ token: token }))
                            .then((response) => {
                                if (response.payload.status === 200) {
                                    navigate(`${token}`)
                                } else if (response.payload.status === 202) {
                                    const data = response.payload.data
                                    if (data === 'Invalid token') {
                                        setError(data)
                                    } else {
                                        regenToken()
                                    }
                                }
                            })
                    }}
                >
                    Submit
                </Button>
                {
                    error && <p style={{ margin: '10px 0', color: 'red' }}>{error}</p>
                }
            </div>
        </Form>
    )
}