import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { Button, Modal, Typography } from "antd";
import { InfoCircle } from '../../utility/components/InfoCircle.jsx'
import '../styles/metrics.scss'

const { Text } = Typography;

export const Metrics = ({ open, setOpen, item }) => {
    const footer = () => {
        return (
            <div>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </div>
        )
    }

    const manageSpeed = (data) => {
        return [
            { speed: "<25", "Num Cars": data['range1'] },
            { speed: "30", "Num Cars": data['range2'] },
            { speed: "40", "Num Cars": data['range3'] },
            { speed: "50", "Num Cars": data['range4'] },
            { speed: ">55", "Num Cars": data['range5'] }
        ]
    }

    const manageDistance = (data) => {
        return [
            { distance: "<164", "Num Cars": data['range1'] },
            { distance: "205", "Num Cars": data['range2'] },
            { distance: "287", "Num Cars": data['range3'] },
            { distance: "369", "Num Cars": data['range4'] },
            { distance: "451", "Num Cars": data['range5'] },
            { distance: "533", "Num Cars": data['range6'] },
            { distance: "615", "Num Cars": data['range7'] },
            { distance: ">656", "Num Cars": data['range8'] }
        ]
    }

    const manageSeen = (data) => {
        const getTimeOffset = (time, offset) => {
            const newTime = new Date(time - offset * 5000 * 60)
            const minutes = `00${newTime.getMinutes()}`
            return `${newTime.getHours()}:${minutes.slice(-2)}`
        }
        const time = new Date(data.timestamp)
        return [
            { name: getTimeOffset(time, 4), "seen": data.data[0] },
            { name: getTimeOffset(time, 3), "seen": data.data[1] },
            { name: getTimeOffset(time, 2), "seen": data.data[2] },
            { name: getTimeOffset(time, 1), "seen": data.data[3] },
            { name: getTimeOffset(time, 0), "seen": data.data[4] }
        ]
    }

    return (
        <div>
            {
                item.distancehistogram !== undefined &&
                <Modal
                    open={open}
                    footer={footer()}
                    onCancel={() => setOpen(false)}
                >
                    <div className="title">
                        <Text strong>Distance Histogram (ft):</Text>
                        <InfoCircle info={"The distance from where the car merged out of the protected lane."} />
                    </div>
                    <BarChart
                        width={425}
                        height={200}
                        margin={{bottom: 10}}
                        data={manageDistance(item.distancehistogram[0])}
                    >
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <XAxis label={{viewBox: {x: 230, y: 190, height: 0, width : 0}, value: 'Merge distance'}} dataKey="distance" />
                        <YAxis label={{viewBox: {x: 0, y: 0, height: 175, width: 30}, value: 'Num Cars', angle: -90}} />
                        <Tooltip />
                        <Bar dataKey="Num Cars" fill="#ff9500" />
                    </BarChart>

                    <div className="title space">
                        <Text strong>Speed Histogram (mph):</Text>
                        <InfoCircle info={"How fast cars are going near the vehicle."}/>
                    </div>
                    <BarChart
                        width={425}
                        height={200}
                        margin={{bottom: 10}}
                        data={manageSpeed(item?.speedhistogram[0])}
                    >
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <XAxis label={{viewBox: {x: 230, y: 190, height: 0, width : 0}, value: 'Speed'}} dataKey="speed" />
                        <YAxis label={{viewBox: {x: 0, y: 0, height: 175, width: 30}, value: 'Num Cars', angle: -90}} />
                        <Tooltip />
                        <Bar dataKey="Num Cars" fill="#ff9500" />
                    </BarChart>

                    <Text strong>Average cars/minute:</Text><Text code>{item?.average_vehicles_seen}</Text>
                </Modal>
            }
        </div>

    )
}