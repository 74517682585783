import { Typography } from "antd";
import { VehicleSection } from "./sections/VehicleSection";

const { Title } = Typography;

export const VehiclesConfig = ({ configuration, action, setAction, setManaging, setSave }) => {
    return (
        <div className="manageBox" style={{}}>
            <Title level={2} style={{ textDecoration: 'underline' }}>Vehicle Management</Title>
            <VehicleSection
                configuration={configuration}
                vehicles={configuration.Vehicles}
                setManaging={setManaging}
                action={action}
                setAction={setAction}
                setSave={setSave}
            />
        </div>
    )
}