import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import VehiclesMarkerGen from "./VehiclesMarkerGen";
import RecenterAutomatically from "./RecenterAutomatically";
import "./styles/map.scss"

const Map = ({ vehicles, mapCoord, handleClick, selectedUnitID }) => {
    const [map, setMap] = useState(null);

    return (
        <MapContainer
            whenReady={setMap}
            center={mapCoord}
            zoom={12}
            scrollWheelZoom={true}
            doubleClickZoom={false}
            className='map'
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                vehicles !== undefined &&
                vehicles.map(vehicle => <VehiclesMarkerGen handleClick={handleClick}
                    map={map?.target}
                    location={[vehicle.gps_latitude, vehicle.gps_longitude]}
                    key={vehicle.unit_id}
                    unit_id={vehicle.unit_id}
                    selectedUnit={selectedUnitID}
                    timeout={1000 * 60}  // The marker will turn red after 1 minute of no status updates
                    item={vehicle}
                />)
            }
            <RecenterAutomatically lat={mapCoord[0]} lng={mapCoord[1]} />
        </MapContainer>
    )
}


export default Map;