import { Menu } from "antd";
import React from 'react';
import "./styles/vehicle_list.scss"


const Vehicle = ({ item, selectedUnit }) => {
  const label = (date) => {
    const checkOnlineStatus = (last_seen) => {
      let now = new Date();
      const last_date = new Date(last_seen);
      const diff = Math.abs(last_date - now);

      return diff > 60000 ? "offline" : "online"
    }
    return (
        <div className="label">
          <p className={checkOnlineStatus(date)}>{item.vehicle_name}</p>
        </div>
    )
  }

  return (
    <div>
      <Menu
        style={{ margin: 2 }}
        theme="light"
        mode="vertical"
        selectedKeys={[selectedUnit]}
        items={
          [{ key: item.unit_id, label: label(item.status_datetime) }]
        }
      />
    </div>);
}

export default Vehicle;