import { useCallback, useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import '../../../../styles/GroupConfig.scss';
import { GroupSection } from "./sections/GroupSection";
import { SubgroupSection } from "./sections/SubgroupSection";
import { UserSection } from "./sections/UserSection";
import { VehicleSection } from "./sections/VehicleSection";
import { Button } from "antd";

export const GroupConfig = ({ mainGroup, configuration, action, setAction, setSave, setManaging, refs, setOpenHieracrchyModal }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [tempConfig, setTempConfig] = useState({});
    const [groupPath, setGroupPath] = useState([])
    const [hierarchy, setHierarchy] = useState({});
    const [unassignedUsers, setUnassignedUsers] = useState([]);
    const [unassignedVehicles, setUnassignedVehicles] = useState([]);
    const [firstSubgroups, setFirstSubgroups] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);

    const buttonStyle = {
        fontWeight: 'bold',
        fontSize: 'x-large',
        padding: '0px',
        margin: '0px 5px'
    }

    const formatPath = useCallback((groupPath, groups) => {
        if (groups.length) {
            return <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                    groupPath.map((value, i) => {
                        if (value === -1) {
                            return groupPath.length - 1 ?
                                <div key={`${mainGroup}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Button

                                        style={buttonStyle}
                                        onClick={() => setSelectedIndex(-1)}
                                        type="link"
                                    >
                                        {mainGroup}
                                    </Button>
                                    <p style={{ userSelect: 'none' }}>
                                        -&gt;
                                    </p>
                                </div> :
                                <p key={`${mainGroup}`}>{mainGroup}</p>
                        } else {
                            return groupPath.length - 1 === i ?
                                <p key={`${groups[value].name}`}>
                                    {groups[value].name}
                                </p> :
                                <div key={`${groups[value].name}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Button
                                        style={buttonStyle}
                                        onClick={() => setSelectedIndex(value)}
                                        type="link"
                                    >
                                        {groups[value].name}
                                    </Button>
                                    <p style={{ userSelect: 'none' }}>
                                        -&gt;
                                    </p>
                                </div>
                        }
                    })
                }
            </div>
        } else {
            return <div>{mainGroup}</div>
        }
    }, [groupPath])

    const findSubgroupPath = (groups, target) => {
        let path = []
        for (let group of groups) {
            path = [group.name]
            if (group.name === target) {
                return path
            }
            path = path.concat(findSubgroupPath(group.subgroups, target))
        }
        return path
    }

    const isSubgroup = (target, group, iteration = 0) => {
        if (group.name === target) {
            return group.name === target && !!iteration
        } else {
            return group.subgroups.reduce((accumulator, curVal) => {
                return accumulator || isSubgroup(target, curVal, iteration + 1)
            }, false)
        }
    }

    const findGroup = (groups, target) => {
        let i = 0
        for (let group of groups) {
            if (group.name === target) {
                return i
            }
            i += 1
        }
        return -1
    }

    const genNumHierarchy = (groups, subgroupList, hierarchyDict = {}, parentGroup = '', row = 0) => {
        groups.map((group, i) => {
            if (!groups.reduce((acc, curVal) => acc || isSubgroup(group.name, curVal), false)) {
                if (parentGroup) {
                    hierarchyDict[group.name] = { parentGroup: parentGroup, path: hierarchyDict[parentGroup].path.concat(findGroup(subgroupList, group.name)) }
                } else {
                    hierarchyDict[group.name] = { parentGroup: parentGroup, path: [-1] }
                }
                if (group.subgroups.length > 0) {
                    genNumHierarchy(group.subgroups, subgroupList, hierarchyDict, group.name, row + 1)
                }
            }
        })
        return hierarchyDict
    }

    // const getGroupUsers = (path) => {
    //     let users = []
    //     const newPath = path.slice(1, path.length - 1)
    //     if (newPath.length) {
    //         for (let index of newPath) {
    //             users = users.concat(configuration.Groups[index].users.map((user) => { return { group: configuration.Groups[index].name, user: user } }))
    //         }
    //     }
    //     if (path.length > 1) {
    //         return unassignedUsers.map((user) => { return { group: mainGroup, user: user } }).concat(users)
    //     } else {
    //         return []
    //     }
    // }

    useEffect(() => {
        if (mainGroup) {
            setGroupPath([-1])
        }
    }, [mainGroup])

    useEffect(() => {
        if (mainGroup && configuration.Groups) {
            setHierarchy(genNumHierarchy([{ name: mainGroup, subgroups: configuration.Groups }], configuration.Groups))
            // Find unassigned vehicles and users
            let assignedUsers = []
            let assignedVehicles = []
            for (let group of configuration.Groups) {
                assignedUsers = assignedUsers.concat(group.users)
                assignedVehicles = assignedVehicles.concat(group.vehicles)
            }
            let leftOverUsers = configuration.Users.map((user) => user.name)
            let leftOverVehicles = configuration.Vehicles.map((vehicle) => vehicle.name)
            for (let user of assignedUsers) {
                leftOverUsers = leftOverUsers.slice(0, leftOverUsers.indexOf(user)).concat(leftOverUsers.slice(leftOverUsers.indexOf(user) + 1))
            }
            for (let vehicle of assignedVehicles) {
                leftOverVehicles = leftOverVehicles.slice(0, leftOverVehicles.indexOf(vehicle)).concat(leftOverVehicles.slice(leftOverVehicles.indexOf(vehicle) + 1))
            }
            setUnassignedUsers(leftOverUsers)
            setUnassignedVehicles(leftOverVehicles)
        }
    }, [mainGroup, configuration.Groups])

    useEffect(() => {
        if (hierarchy && mainGroup) {
            let firstGroups = Object.keys(hierarchy).map((group) => {
                return {
                    name: group,
                    ...hierarchy[group]
                }
            })
            for (let group of Object.keys(hierarchy)) {
                if (hierarchy[group].parentGroup !== mainGroup) {
                    const index = findGroup(firstGroups, group)
                    firstGroups = firstGroups.slice(0, index).concat(firstGroups.slice(index + 1))
                }
            }
            setFirstSubgroups(firstGroups.map((group) => configuration.Groups.find((configGroup) => configGroup.name === group.name)))
        }
    }, [hierarchy, mainGroup])

    useEffect(() => {
        if (action !== 'remove' && action !== 'rename') {
            setAction('')
        }
        if (selectedIndex > -1) {
            setGroupPath(hierarchy[configuration.Groups[selectedIndex].name].path)
        } else {
            setGroupPath([-1])
        }
    }, [selectedIndex])

    // useEffect(() => {
    //     setVisibleUsers(getGroupUsers(groupPath))
    // }, [groupPath])

    useEffect(() => {
        if (selectedIndex > -1 && selectedIndex < configuration.Groups.length) {
            setTempConfig(configuration.Groups[selectedIndex])
        } else {
            setTempConfig({ users: unassignedUsers, vehicles: unassignedVehicles, subgroups: firstSubgroups })
        }
        if (action) {
            if (action === 'remove' && selectedIndex > -1 && selectedIndex < configuration.Groups.length) {
                setSave(configuration.Groups[selectedIndex].name)
                setSelectedIndex(-1)
            }
        }
    }, [configuration, selectedIndex, unassignedUsers, firstSubgroups])

    return (
        <div className="manageBox" style={{ width: '100%' }}>
            <div>
                <Title level={2} style={{ textDecoration: 'underline' }}>Group Management</Title>
                <Title
                    level={3}
                    style={{ margin: '0', marginLeft: '15px', marginBottom: '15px' }}
                >
                    {formatPath(groupPath, configuration.Groups)}
                </Title>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '10px' }}>
                    <GroupSection
                        configuration={configuration.Groups}
                        hierarchy={hierarchy}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        action={action}
                        setAction={setAction}
                        setSave={setSave}
                        setManaging={setManaging}
                        groupOptionsRef={refs.groupOptionsRef}
                        groupListRef={refs.groupListRef}
                        setOpenHieracrchyModal={setOpenHieracrchyModal}
                    />
                    <SubgroupSection
                        configuration={configuration.Groups}
                        firstSubgroups={firstSubgroups}
                        selectedIndex={selectedIndex}
                        tempConfig={tempConfig}
                        setTempConfig={setTempConfig}
                        action={action}
                        setAction={setAction}
                        setSave={setSave}
                        setManaging={setManaging}
                        subgroupOptionsRef={refs.subgroupOptionsRef}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <UserSection
                        configuration={configuration.Groups}
                        selectedIndex={selectedIndex}
                        users={selectedIndex === -1 ? unassignedUsers : configuration.Users.map((user) => user.name)}
                        // visibleUsers={visibleUsers}
                        tempConfig={tempConfig}
                        setTempConfig={setTempConfig}
                        action={action}
                        setAction={setAction}
                        setSave={setSave}
                        setManaging={setManaging}
                        userOptionsRef={refs.userOptionsRef}
                    />
                    <VehicleSection
                        configuration={configuration.Groups}
                        selectedIndex={selectedIndex}
                        // vehicles={configuration.Vehicles.map((v) => v.name)}
                        unassignedVehicles={unassignedVehicles}
                        tempConfig={tempConfig}
                        setTempConfig={setTempConfig}
                        action={action}
                        setAction={setAction}
                        setSave={setSave}
                        setManaging={setManaging}
                        vehicleOptionsRef={refs.vehicleOptionsRef}
                    />
                </div>
            </div>
        </div>
    )
}