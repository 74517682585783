import { useEffect, useState } from "react"

export const Hieracrchy = ({ mainGroup, groupConfigurations }) => {
    const [hierarchy, setHieracrchy] = useState(null);  // I think this might just be the html
    const style = {
        border: '1px solid black',
        width: 'fit-content',
        padding: '5px 10px',
        margin: '0 auto',
        marginBottom: '10px',
    }

    const isSubgroup = (target, group, iteration = 0) => {
        if (group.name === target) {
            return group.name === target && !!iteration
        } else {
            return group.subgroups.reduce((accumulator, curVal) => {
                return accumulator || isSubgroup(target, curVal, iteration + 1)
            }, false)
        }
    }

    const generateHierarchy = (groups, row = 0) => {
        return (
            <ul key={row} style={{ marginLeft: `${row * 0.5}em` }}>
                {
                    groups.map((group, i) => {
                        if (!groups.reduce((acc, curVal) => acc || isSubgroup(group.name, curVal), false)) {
                            return <li key={`${row}${i}`}>
                                {group.name}
                                {
                                    group.subgroups.length > 0 && generateHierarchy(group.subgroups, row + 1)
                                }
                            </li>
                        }
                    })
                }
            </ul>
        )
    }

    const findGroup = (groups, target) => {
        let i = 0
        for (let group of groups) {
            if (group.name === target) {
                return i
            }
            i += 1
        }
        return -1
    }

    const genNumHierarchy = (groups, subgroupList, hierarchyDict = {}, parentGroup = '', row = 0) => {
        groups.map((group, i) => {
            console.log(group)
            console.log(group.name, '|', !groups.reduce((acc, curVal) => acc || isSubgroup(group.name, curVal), false))
            if (!groups.reduce((acc, curVal) => acc || isSubgroup(group.name, curVal), false)) {
                console.log(hierarchyDict)
                if (parentGroup) {
                    hierarchyDict[group.name] = { parentGroup: parentGroup, path: hierarchyDict[parentGroup].path.concat(findGroup(subgroupList, group.name)) }
                } else {
                    hierarchyDict[group.name] = {parentGroup: parentGroup, path: [-1]}
                }
                if (group.subgroups.length > 0) {
                    console.log(hierarchyDict)
                    genNumHierarchy(group.subgroups, subgroupList, hierarchyDict, group.name, row + 1)
                }
            }
        })
        return hierarchyDict
    }

    useEffect(() => {
        setHieracrchy(<div>
            {generateHierarchy([{ name: mainGroup, subgroups: groupConfigurations }])}
        </div>)
        console.log(genNumHierarchy([{ name: mainGroup, subgroups: groupConfigurations }], groupConfigurations))
    }, [mainGroup, groupConfigurations])

    return (
        <div>
            {
                hierarchy
            }
        </div>
    )
}