import { AutoComplete, Button, Divider, Typography } from "antd";
import '../../../../../styles/Section.scss';
import { useEffect, useState } from "react";

const { Title } = Typography;

export const SubgroupSection = ({ configuration, firstSubgroups, selectedIndex, tempConfig, setTempConfig, action, setAction, setSave, setManaging, subgroupOptionsRef }) => {
    const [subgroup, setSubgroup] = useState('');
    const [availableGroups, setAvailableGroups] = useState([])

    const checkDoubly = (target, group) => {
        if (!group.subgroups.length) {
            return group.name === target ? 1 : 0
        } else {
            return Math.max(
                target === group.name ? 1 : 0,
                group.subgroups.reduce((counter, curVal) => counter + checkDoubly(target, curVal), 0)
            )
        }
    }

    const isParent = (group, target) => {
        let bool = false
        for (let subgroup of group.subgroups) {
            if (subgroup.name === target) {
                return true
            } else {
                return isParent(subgroup, target)
            }
        }
        return false
    }

    useEffect(() => {
        if (selectedIndex > -1) {
            let groups = []
            for (let group of firstSubgroups) {
                if (group.name !== configuration[selectedIndex].name && !isParent(group, configuration[selectedIndex].name)) {
                    groups = groups.concat({value: group.name, label: group.name})
                }
            }
            setAvailableGroups(groups.length ? groups : [{ value: 'No subgroups left' }])
        }
    }, [firstSubgroups, configuration, selectedIndex])

    return (
        <div>
            <div className="groups">
                <div className="buttons" ref={subgroupOptionsRef}>
                    <Title className="title" level={4}>Options</Title>
                    <Button
                        className="button"
                        disabled={!configuration[selectedIndex]?.name || action}
                        onClick={() => {
                            setManaging('Groups')
                            setAction('addSubgroup')
                            setTempConfig({
                                ...configuration[selectedIndex],
                                subgroups: [...configuration[selectedIndex].subgroups, { name: '', subgroups: [], users: [], vehicles: [] }]
                            })
                        }}
                    >
                        Assign Subgroup
                    </Button>
                    <Button
                        className="button"
                        disabled={!configuration[selectedIndex]?.name || action || !configuration[selectedIndex]?.subgroups.length}
                        onClick={() => {
                            setManaging('Groups')
                            setAction('removeSubgroup')
                        }}
                    >
                        Remove Subgroup
                    </Button>
                    <Button
                        className="button"
                        disabled={action !== 'addSubgroup' && action !== 'removeSubgroup'}
                        onClick={() => {
                            setTempConfig(configuration[selectedIndex])
                            setAction('')
                        }}
                    >
                        Cancel
                    </Button>
                </div>
                <Divider className="divider" type="vertical" />
                <div>
                    <Title level={4}>Subgroups</Title>
                    <div className="list">
                        {
                            tempConfig?.subgroups?.length ?
                                <ul className="listConfig">
                                    {
                                        tempConfig.subgroups?.map((child, i) => {
                                            return child.name !== '' || action === 'addSubgroup' ? <li
                                                className={`configItem ${action === 'removeSubgroup' ? 'configItemDelete' : ''}`}
                                                onClick={() => {
                                                    if (action === 'removeSubgroup') {
                                                        setSave([tempConfig.name, child.name])
                                                    }
                                                }}
                                                key={i}
                                            >
                                                {
                                                    child.name === '' ?
                                                        <AutoComplete
                                                            style={{ width: 200, }}
                                                            allowClear
                                                            backfill
                                                            options={availableGroups}
                                                            placeholder="Select a subgroup"
                                                            value={subgroup}
                                                            onChange={(value, option) => {
                                                                setSubgroup(value)
                                                            }}
                                                            filterOption={(inputVal, option) =>
                                                                option.value.toUpperCase().indexOf(inputVal.toUpperCase()) !== -1
                                                            }
                                                            onSelect={(value, option) => {
                                                                if (value !== 'No subgroups left') {
                                                                    setSave([configuration[selectedIndex].name, value])
                                                                }
                                                                setSubgroup('')
                                                            }}
                                                        /> :
                                                        child.name
                                                }
                                            </li> : ''
                                        })
                                    }
                                </ul> :
                                <p className="subgroupText">There are currently no subgroups assigned to this group.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}