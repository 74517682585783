import * as React from "react";
import "./CardFrame.scss";

const CardFrame = (props) => {
  return (
    <div className={`card-frame-12 ${props.className || ""}`}>
      <span className="num-04">{props.num || "04"}</span>
      <div className="flex-container-7">
        <span className="machine-learning-ai">
          {props.machineLearningAi || "Machine learning &amp; AI"}
        </span>
        <span className="deep-neural-networks">
          {props.deepNeuralNetworks ||
            "Deep neural networks process the data in four independent, embedded, VPUs for real-time lane detection, object detection, and depth perception."}
        </span>
      </div>
    </div>
  );
};
export default CardFrame;