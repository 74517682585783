import { Button, Divider, Typography } from 'antd';
import { useSelector } from "react-redux";
import '../../../../../styles/Section.scss';
import { UserListElement } from '../UserListElement';
import { CreateUserModal } from '../../../CreateUserModal';

const { Title } = Typography

export const UserSection = ({ configuration, selectedUser, setSelectedUser, action, setAction, setManaging, userLicences, setSave, refs, usernameInfo }) => {
    const editor = useSelector(state => state.app.profile.username)

    const notAllManagers = () => {
        let numNotManagers = 0
        for (let user of configuration) {
            numNotManagers += user.role === 'analyst' || user.role === 'user' ? 1 : 0
        }

        return !!numNotManagers
    }

    return (
        <div className='groups'>
            <div className='buttons'>
                <Title className='title' level={4}>Options</Title>
                <Button
                    ref={refs.createUserRef}
                    className='button'
                    title={`User Licences Left: ${userLicences.left}/${userLicences.max}`}
                    onClick={() => {
                        setManaging('Users')
                        setAction('createUser')
                        setSelectedUser(-1)
                    }}
                    disabled={action || userLicences.left <= 0}
                >
                    Create User
                </Button>
                <Button
                    ref={refs.removeUserRef}
                    className='button'
                    disabled={action || !notAllManagers()}
                    onClick={() => {
                        setManaging('Users')
                        setAction('removeUser')
                        setSelectedUser(-1)
                    }}
                >
                    Remove User
                </Button>
                <Button
                    className='button'
                    disabled={action || !notAllManagers()}
                    onClick={() => {
                        setManaging('Users')
                        setAction('changeRole')
                        setSelectedUser(-1)
                    }}
                >
                    Change Role
                </Button>
                <Button
                    className='button'
                    disabled={action !== 'removeUser' && action !== 'createUser' && action !== 'changeRole'}
                    onClick={() => {
                        usernameInfo.setUsername('')
                        usernameInfo.setValidUsername('')
                        setAction('')
                    }}
                >
                    Cancel
                </Button>
            </div>
            <Divider className='divider' type='vertical' />
            <div>
                <Title level={4}>Users</Title>
                <div className='list'>
                    {
                        action === 'createUser' ?
                            <CreateUserModal
                                usernameInfo={usernameInfo}
                            /> :
                            <ul className='listConfig'>
                                {
                                    configuration.map((user, i) => {
                                        return editor === user.name ? '' : <UserListElement
                                            key={i}
                                            className={'configItem'}
                                            index={i}
                                            value={user.name}
                                            role={user.role}
                                            setRole={setSave}
                                            action={action}
                                            selected={configuration[selectedUser]}
                                            setSelected={setSelectedUser}
                                        />
                                    })
                                }
                            </ul>
                    }
                </div>
            </div>
        </div>
    )
}