import React from 'react';
import { useWebRtcStream } from './useWebRtcStream';

const Camera = ({robotId, appId, cameraStream }) => {
  const videoRef = React.useRef(null);
  console.log("CAMERA PROPS", {robotId, appId, cameraStream})
  const [stream, debugState] = useWebRtcStream(
    // `https://${agentIp}:9010/webrtc?name=${cameraStream}&appId=${appId}`,
      // 'https://hub.luxonis.com/api/robot/f2085213-786c-47bf-b1c3-a5165b671cca/apps/d08b6e0d-3c92-455a-9cd7-349c8e1d47a8/stream/d08b6e0d-3c92-455a-9cd7-349c8e1d47a8/4007daba-2780-42ea-89be-e29c5e0c0a21',
    `/api/robot/${robotId}/apps/${appId}/stream/${appId}%2F${cameraStream}`,
    !!(cameraStream && appId),
  );

  // console.log("CAMERA DEBUG", stream, debugState)
  console.log("CAMERA CON", debugState.iceConnectionLog)
  console.log("CAMERA GAT", debugState.iceGatheringLog)
  console.log("CAMERA SIG", debugState.signalingLog)
  // console.log(robotId)

  React.useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.play().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);

  return (
      <div>
        <div className="VideoPlayer root">
          <video className="VideoPlayer video" ref={videoRef} muted autoPlay playsInline />
        </div>
      </div>
  );
};

export default Camera;



