import { useEffect, useState } from 'react';
import { Button, Divider, Input, Typography } from 'antd';

const { Title } = Typography;

export const VehicleSection = ({ vehicles, action, setAction, setManaging, setSave }) => {
    const [selectedVehicle, setSelectedVehicle] = useState(-1)
    const [newVehicleName, setNewVehicleName] = useState('');

    useEffect(() => {
        setSelectedVehicle(-1)
    }, [vehicles])

    useEffect(() => {
        if (selectedVehicle !== -1) {
            setNewVehicleName(vehicles[selectedVehicle].name)
        }
    }, [selectedVehicle])

    return (
        <div>
            <div className="groups">
                <div className="buttons">
                    <Title className="title" level={4}>Options</Title>
                    <Button
                        className="button"
                        disabled={action}
                        onClick={() => {
                            setManaging('Vehicles')
                            setAction('renameVehicle')
                        }}
                    >
                        Rename Vehicle
                    </Button>
                    <Button
                        className='button'
                        disabled={action != 'renameVehicle' || newVehicleName === '' || newVehicleName === vehicles[selectedVehicle]?.name || selectedVehicle < 0}
                        onClick={() => {
                            setSave([vehicles[selectedVehicle].name, newVehicleName])
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        className="button"
                        disabled={action !== 'renameVehicle'}
                        onClick={() => {
                            setAction('')
                            setSelectedVehicle(-1)
                        }}
                    >
                        Cancel
                    </Button>
                </div>
                <Divider className="divider" type="vertical" />
                <div>
                    <Title level={4}>Vehicles</Title>
                    <div className="list">
                        {
                            vehicles?.length ?
                                <ul className="listConfig">
                                    {
                                        vehicles?.map((child, i) => {
                                            return <li
                                                className={action === 'renameVehicle' ? 'configItem configItemHover' : `configItem`}
                                                onClick={() => {
                                                    if (action === 'renameVehicle') {
                                                        setSelectedVehicle(i)
                                                    }
                                                }}
                                                key={i}
                                            >
                                                {
                                                    selectedVehicle === i ?
                                                        <Input
                                                            style={{
                                                                width: 200,
                                                            }}
                                                            onChange={(e) => {
                                                                setNewVehicleName(e.target.value)
                                                            }}
                                                            value={newVehicleName}
                                                            autoFocus
                                                        /> :
                                                        child.name
                                                }
                                            </li>
                                        })
                                    }
                                </ul> :
                                <p className="subgroupText">There are currently no available vehicles.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}