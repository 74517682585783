import { Checkbox, Divider, Modal } from "antd"
import { useEffect, useState } from "react"

export const AvailableFilesList = ({ files, setFilesWanted, open, setOpen }) => {
    const [checkboxStatuses, setCheckboxStatuses] = useState([])
    const [allStatus, setAllStatus] = useState(0)

    const handleOk = () => {
        let list = []
        for (let status of checkboxStatuses) {
            if (status.status) {
                list = list.concat([status.file])
            }
        }
        setOpen(false)
        setFilesWanted(list)
    }

    const handleAllChange = (newStatus) => {
        setCheckboxStatuses(cS => cS.map((status) => {
            return {
                file: status.file,
                'status': newStatus
            }
        }))
    }

    const handleChange = (index, newStatus) => {
        setCheckboxStatuses(cS => cS.map((status, i) => {
            if (i === index) {
                return {
                    file: status.file,
                    "status": newStatus
                }
            } else {
                return status
            }
        }))
    }

    useEffect(() => {
        setCheckboxStatuses(files.map((file) => {
            return {
                "file": file,
                status: true
            }
        }))
    }, [files])

    useEffect(() => {
        setAllStatus(0)
        checkboxStatuses.map((status) => {
            if (status.status) {
                setAllStatus((count) => count + 1)
            }
        })
    }, [checkboxStatuses])

    return (
        <Modal
            width="300px"
            open={open}
            okText={"Download"}
            title={"Available Files for Download"}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            okButtonProps={
                { disabled: allStatus  === 0 }
            }
        >
            {
                checkboxStatuses.length &&
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox checked={allStatus === checkboxStatuses.length} onChange={(e) => handleAllChange(e.target.checked)} />
                        <p style={{ marginLeft: '5px' }}>All</p>
                    </div>
                    <Divider style={{ margin: '5px 0px' }} />
                    {
                        files.map((file, i) => <div key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Checkbox checked={checkboxStatuses[i].status} onChange={(e) => handleChange(i, e.target.checked)} />
                            <p style={{ marginLeft: '5px' }}>{file}</p>
                        </div>)
                    }
                </div>
            }
        </Modal>
    )
}