// For individual characters
const isLower = (char) => {
    return char === char.toLowerCase();
}

const isUpper = (char) => {
    return char === char.toUpperCase();
}

const isNum = (char) => {
    return Number(char) > -1;
}

const isSpecialChar = (char) => {
    const LEGAL_SYMBOLS = ['!', '#', '$', '%', '&', '(', ')', '*', '+', ',', '-', '.', '<', '=', '>', '?', '@', '^', '_', '|', '~'];
    return !!LEGAL_SYMBOLS.find((e) => e === char.charAt(0));
}

const isInvalidSpecialChar = (char) => {
    let range = [...Array(33).keys()];
    const ILLEGAL_SYMBOLS = [...range, 34, 39, 47, 58, 59, 91, 92, 93, 96, 123, 125, 127];
    return !!ILLEGAL_SYMBOLS.find((e) => e === char.charCodeAt(0));
}

// For the entire password
const minPasswordLength = (password, minLength = 8) => {
    return password.length >= minLength;
}

const contains = (password, func, minAmount = 1) => {
    let counter = 0;
    for (let char of password) {
        if (func(char)) {
            counter += 1;
        }
        if (counter === minAmount) {
            return true;
        }
    }
    return false;
}

export const validatePassword = (password, minLength = 8, minLower = 1, minUpper = 1, minNum = 1, minSpecial = 1) => {
    let errors = [];
    if (!minPasswordLength(password, minLength)) {
        errors = errors.concat(`Your password needs to have at least ${minLength} characters.`);
    }
    if (!contains(password, isLower, minLower)) {
        errors = errors.concat(`Your password needs to have at least ${minLower} lowercase characters.`);
    }
    if (!contains(password, isUpper, minUpper)) {
        errors = errors.concat(`Your password needs to have at least ${minUpper} uppercase characters.`);
    }
    if (!contains(password, isNum, minNum)) {
        errors = errors.concat(`Your password needs top have at least ${minNum} numbers.`)
    }
    if (!contains(password, isSpecialChar, minSpecial)) {
        errors = errors.concat(`Your password needs to have at least ${minSpecial} special characters.`)
    }
    if (contains(password, isInvalidSpecialChar)) {
        errors = errors.concat('Your password is not allowed of have other special characters that are not listed.')
    }
    return errors
}