import { useEffect, useState } from "react";
import { AutoComplete, Button, Divider, Typography } from "antd";

const { Title } = Typography;

export const UserSection = ({ configuration, selectedIndex, users, tempConfig, setTempConfig, action, setAction, setSave, setManaging, userOptionsRef }) => {
    const [validAction, setValidAction] = useState(action === 'addSubUser' || action === 'removeSubUser')
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [wantedUser, setWantedUser] = useState('')

    const getAvailableUsers = () => {
        let leftOverUsers = users.map((user) => { return { value: user, label: user } })
        for (let subgroup of configuration) {
            for (let user of subgroup.users) {
                leftOverUsers = leftOverUsers.filter((lOUUser) => lOUUser.value !== user)
            }
        }
        return leftOverUsers.length ? leftOverUsers : [{ value: 'No users left' }]
    }

    const getSubgroupUsers = (group) => {
        let users = []
        if (group.subgroups.length === 0) {
            return users
        } else {
            for (let subgroup of group.subgroups) {
                users = users.concat(subgroup.users.map((user) => { return { group: subgroup.name, user: user } }))
                users = users.concat(getSubgroupUsers(subgroup))
            }
            return users
        }
    }

    useEffect(() => {
        setValidAction(action === 'addSubUser' || action === 'removeSubUser')
    }, [action])

    useEffect(() => {
        if (tempConfig?.subgroups) {
            setVisibleUsers(getSubgroupUsers(tempConfig))
        }
    }, [tempConfig])

    return (
        <div>
            <div className="groups">
                <div className="buttons" ref={userOptionsRef}>
                    <Title className="title" level={4}>Options</Title>
                    <Button
                        className="button"
                        disabled={action || selectedIndex === -1}
                        onClick={() => {
                            setManaging('Groups')
                            setAction('addSubUser')
                            setTempConfig({
                                ...configuration[selectedIndex],
                                users: [...configuration[selectedIndex].users, '']
                            })
                        }}
                    >
                        Assign User
                    </Button>
                    <Button
                        className="button"
                        disabled={action || selectedIndex === -1 || !configuration[selectedIndex].users.length}
                        onClick={() => {
                            setManaging('Groups')
                            setAction('removeSubUser')
                        }}
                    >
                        Remove User
                    </Button>
                    <Button
                        className="button"
                        disabled={!validAction}
                        onClick={() => {
                            setTempConfig(configuration[selectedIndex])
                            setAction('')
                        }}
                    >
                        Cancel
                    </Button>
                </div>
                <Divider className="divider" type="vertical" />
                <div>
                    <Title level={4}>Users</Title>
                    <div className="list">
                        {
                            tempConfig?.users?.length || visibleUsers.length ?
                                <ul className="listConfig">
                                    {
                                        tempConfig.users?.map((user, i) => {
                                            return <li
                                                className={`configItem ${action === 'removeSubUser' ? 'configItemDelete' : ''}`}
                                                onClick={() => {
                                                    if (action === 'removeSubUser') {
                                                        setSave([configuration[selectedIndex].name, user])
                                                    }
                                                }}
                                                key={i}
                                            >
                                                {
                                                    user === '' ?
                                                        <AutoComplete
                                                            style={{ width: 200, }}
                                                            allowClear
                                                            backfill
                                                            options={getAvailableUsers()}
                                                            placeholder="Select user"
                                                            value={wantedUser}
                                                            onChange={(value, option) => {
                                                                setWantedUser(value)
                                                            }}
                                                            filterOption={(inputVal, option) =>
                                                                option.value.toUpperCase().indexOf(inputVal.toUpperCase()) !== -1
                                                            }
                                                            onSelect={(value, option) => {
                                                                if (value !== 'No users left') {
                                                                    setSave([configuration[selectedIndex].name, value])
                                                                }
                                                                setWantedUser('')
                                                            }}
                                                        /> :
                                                        user
                                                }
                                            </li>
                                        })
                                    }
                                    {
                                        !action &&
                                        visibleUsers.map((user) => {
                                            return <li
                                                style={{ color: 'gray' }}
                                                title={`Assigned to: ${user.group}`}
                                                className="configItem"
                                                key={`${user.user}`}
                                            >
                                                {user.user}
                                            </li>
                                        })
                                    }
                                </ul> :
                                <p className="subgroupText">There are currently no users assigned to this group.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}