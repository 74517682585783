import React from "react";
import { useNavigate } from "react-router";
import { ALARM_HISTORY_SUMMARY } from "../../../configuration/paths";
import { Checkbox, Typography } from "antd";
import "../styles/notifications.scss"
import { InfoCircle } from "../../utility/components/InfoCircle";

const { Title } = Typography;


export const Notifications = ({
    alertLevelNotifs,
    setAlertLevelNotifs,
    alertTypeNotifs,
    setAlertTypeNotifs,
    receiveNotifs,
    setReceiveNotifs,
    intervals,
    setIntervals,
    setSave
}) => {
    const nav = useNavigate();

    const isAllChecked = () => {
        return receiveNotifs['email']
            // && receiveNotifs['sms']
            && alertLevelNotifs['one']
            && alertLevelNotifs['two']
            && alertLevelNotifs['zero']
            && alertTypeNotifs['auto']
            && alertTypeNotifs['tablet']
            && alertTypeNotifs['man']
            && alertTypeNotifs['none']
            && intervals['daily_email']
            // && intervals['daily_sms']
            && intervals['weekly_email']
            // && intervals['weekly_sms']
            && intervals['monthly_email']
            // && intervals['monthly_sms'];
    };

    const changeChecked = (checked) => {
        setSave(true);
        if (!checked) {
            setReceiveNotifs({ email: false, sms: false });
            setAlertLevelNotifs({ zero: false, one: false, two: false });
            setAlertTypeNotifs({ none: false, man: false, tablet: false, auto: false });
            setIntervals({
                daily_email: false, daily_sms: false,
                weekly_email: false, weekly_sms: false,
                monthly_email: false, monthly_sms: false,
            })
        } else {
            setReceiveNotifs({ email: true });
            // setReceiveNotifs({ email: true, sms: true });
            setAlertLevelNotifs({ zero: true, one: true, two: true });
            setAlertTypeNotifs({ none: true, man: true, tablet: true, auto: true });
            setIntervals({
                daily_email: true,
                weekly_email: true,
                monthly_email: true
            })
            // setIntervals({
            //     daily_email: true, daily_sms: true,
            //     weekly_email: true, weekly_sms: true,
            //     monthly_email: true, monthly_sms: true,
            // })
        }
    };

    const onInputChange = ({ value, setFunction }) => {
        setSave(true)
        setFunction(value)
    };

    return (
        <div className="notificationsDiv">
            <Title level={4}>Receive Notifications By...</Title>
            <div className="checkboxesDiv">
                <Checkbox
                    checked={receiveNotifs['email']}
                    onChange={e => { onInputChange({ value: { ...receiveNotifs, email: e.target.checked }, setFunction: setReceiveNotifs }) }}
                >
                    Email
                </Checkbox>
                <Checkbox
                    disabled
                    checked={receiveNotifs['sms']}
                    onChange={e => { onInputChange({ value: { ...receiveNotifs, sms: e.target.checked }, setFunction: setReceiveNotifs }) }}
                >
                    SMS
                </Checkbox>
            </div>
            <Title level={4}>Hazard Level Notifications</Title>
            <div className="checkboxesDiv">
                <Checkbox
                    checked={alertLevelNotifs['zero']}
                    onChange={e => { onInputChange({ value: { ...alertLevelNotifs, zero: e.target.checked }, setFunction: setAlertLevelNotifs }) }}
                >
                    No Hazard
                </Checkbox>
                <Checkbox
                    checked={alertLevelNotifs['one']}
                    onChange={e => { onInputChange({ value: { ...alertLevelNotifs, one: e.target.checked }, setFunction: setAlertLevelNotifs }) }}
                >
                    Lights
                </Checkbox>
                <Checkbox
                    checked={alertLevelNotifs['two']}
                    onChange={e => { onInputChange({ value: { ...alertLevelNotifs, two: e.target.checked }, setFunction: setAlertLevelNotifs }) }}
                >
                    Sirens
                </Checkbox>
            </div>
            <Title level={4}>Report Type Notifications</Title>
            <div className="checkboxesDiv">
                <Checkbox
                    checked={alertTypeNotifs['none']}
                    onChange={e => { onInputChange({ value: { ...alertTypeNotifs, none: e.target.checked }, setFunction: setAlertTypeNotifs }) }}
                >
                    False Alarm/Near Miss
                </Checkbox>
                <Checkbox
                    checked={alertTypeNotifs['man']}
                    onChange={e => { onInputChange({ value: { ...alertTypeNotifs, man: e.target.checked }, setFunction: setAlertTypeNotifs }) }}
                >
                    Manual
                </Checkbox>
                <br />
                <Checkbox
                    checked={alertTypeNotifs['tablet']}
                    onChange={e => { onInputChange({ value: { ...alertTypeNotifs, tablet: e.target.checked }, setFunction: setAlertTypeNotifs }) }}
                >
                    Tablet
                </Checkbox>
                <Checkbox
                    checked={alertTypeNotifs['auto']}
                    onChange={e => { onInputChange({ value: { ...alertTypeNotifs, auto: e.target.checked }, setFunction: setAlertTypeNotifs }) }}
                >
                    Automatic
                </Checkbox>
            </div>
            <div className="reminderTitle">
                <Title level={4}>Summary Reminders</Title>
                <InfoCircle
                    info={
                        <>
                            The summaries are made at the start of their respective times at midnight UTC and can be viewed <a onClick={e => nav(ALARM_HISTORY_SUMMARY)}>here</a>.
                            <br />
                            <br />
                            These will remind you when the alarm report summaries are available for you to view.
                        </>
                    }
                />
            </div>
            <div>
                <div className="option">
                    <b className="label">Daily</b>
                    <Checkbox
                        checked={intervals['daily_email']}
                        onChange={e => { onInputChange({ value: { ...intervals, daily_email: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        Email
                    </Checkbox>
                    <Checkbox
                        disabled
                        checked={intervals['daily_sms']}
                        onChange={e => { onInputChange({ value: { ...intervals, daily_sms: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        SMS
                    </Checkbox>
                </div>
                <div className="option">
                    <b className="label">Weekly</b>
                    <Checkbox
                        checked={intervals['weekly_email']}
                        onChange={e => { onInputChange({ value: { ...intervals, weekly_email: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        Email
                    </Checkbox>
                    <Checkbox
                        disabled
                        checked={intervals['weekly_sms']}
                        onChange={e => { onInputChange({ value: { ...intervals, weekly_sms: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        SMS
                    </Checkbox>
                </div>
                <div className="option">
                    <b className="label">Monthly</b>
                    <Checkbox
                        checked={intervals['monthly_email']}
                        onChange={e => { onInputChange({ value: { ...intervals, monthly_email: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        Email
                    </Checkbox>
                    <Checkbox
                        disabled
                        checked={intervals['monthly_sms']}
                        onChange={e => { onInputChange({ value: { ...intervals, monthly_sms: e.target.checked }, setFunction: setIntervals }) }}
                    >
                        SMS
                    </Checkbox>
                </div>
            </div>
            <br />
            <Checkbox
                checked={isAllChecked()}
                onChange={e => { changeChecked(e.target.checked) }}
            >
                All
            </Checkbox>
        </div>
    )
};
