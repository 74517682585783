import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';

export const TableRow = ({ rowInfo, handleDetectionClick, counter, selectedCounter, setSelectedCounter, setMapLocation }) => {
    const LEVEL_TO_STR = ['No Hazard', 'Lights', 'Sirens']
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setSelected(selectedCounter === counter)
    }, [selectedCounter])

    return (
        <tr
            className={selected ? 'data selected' : parseInt(rowInfo.trigger_level) ? parseInt(rowInfo.trigger_level) === 2 ? 'data sirens' : 'data lights' : 'data'}
            onClick={() => {
                handleDetectionClick(rowInfo)
                setSelectedCounter(counter)
                setMapLocation(rowInfo.location)
            }}
        >
            <td style={{minWidth: "190px"}}>{rowInfo.vehicle_name ? rowInfo.vehicle_name : rowInfo.serial_number}</td>
            <td style={{minWidth: "190px"}}>{new Date(`${rowInfo.detection_date}+00:00`).toLocaleString()}</td>
            <td style={{minWidth: "100px"}}>{rowInfo.trigger_type}</td>
            <td style={{minWidth: "100px"}}>{LEVEL_TO_STR[rowInfo.trigger_level]}</td>
            <td>{rowInfo.location.latitude && rowInfo.location.longitude ? <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> : <CloseCircleTwoTone twoToneColor={'#ff0000'} />}</td>
        </tr>
    )
}