import { useEffect, useState } from "react"
import { Select } from "antd"

export const UserListElement = ({ className, index, value, role, setRole, action, selected, setSelected }) => {
    const [liClassname, setLiClassname] = useState('');
    const [roleBool, setRoleBool] = useState(false);
    const options = [
        {
            value: 'analyst',
            label: 'Group Analyst'
        },
        {
            value: 'user',
            label: 'Group User'
        }
    ]

    useEffect(() => {
        setRoleBool(role === 'user' || role === 'analyst')
    }, [role])

    useEffect(() => {
        if (action === 'removeUser') {
            if (roleBool) {
                setLiClassname(`${className} ${className}Delete`)
            } else {
                setLiClassname(className)
            }
        } else if (action === 'changeRole') {
            if (roleBool) {
                setLiClassname(`${className} ${className}Hover`)
            } else {
                setLiClassname(className)
            }
        } else {
            setLiClassname(`${className} ${className}Hover`)
        }
    }, [action, roleBool])

    return (
        <li
            title={`${role[0].toUpperCase() + role.slice(1)}`}
            className={liClassname}
            onClick={() => ((action !== 'changeRole' && (action !== 'removeUser' || roleBool)) || (action === 'changeRole' && roleBool)) && setSelected(index)}
        >
            {
                action === 'changeRole' && value === selected?.name ?
                    <Select
                        style={{ width: "151px", margin: '5px 0px' }}
                        showSearch
                        placeholder="Select a role"
                        defaultValue={role}
                        options={options}
                        onChange={(e) => setRole([value, e])}
                    /> :
                    value
            }
        </li>
    )
}