import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "../styles/video.scss"

export const VideoModal = ({ name, url, open, setOpen }) => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        setVideoUrl(url);
    }, [url])

    const title = () => {
        return (
            <div>
                <b>Stream:</b> {name}
            </div>
        )
    }
    return (
        <div>
            {
                videoUrl === url &&
                <Modal
                    footer={null}
                    title={title()}
                    open={open}
                    onCancel={e => setOpen(false)}
                    width={"60%"}
                >
                    {
                        url !== '' ?
                            <video className="video" controls>
                                <source src={videoUrl} type="video/mp4" />
                            </video> :
                            <p>This video does not exist</p>
                    }
                </Modal>
            }
        </div>
    )
}