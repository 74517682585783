import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Modal, notification, Progress, Typography } from "antd";
import DetectionVideo from "./DetectionVideo";
import { DownloadButton } from "./DownloadButton";
import { listVideos } from "../../../store";
import "../styles/detection_modal.scss";

const { Text } = Typography

export const DetectionModal = ({ open, handleClose, title, left_data, alert, setUrl }) => {
    const [api, context] = notification.useNotification({ stack: 5, duration: 0 });
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const [statuses, setStatuses] = useState([]);
    const [percent, setPercent] = useState(0);
    const [videos, setVideos] = useState(undefined);
    const role = useSelector(state => state.app.profile.role);
    const key = 'updatble';

    const closeNotification = () => {
        api.destroy()
        setStatuses([])
        setPercent(0)
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (statuses.length) {
            setTimeout(() => {
                api.info({
                    key,
                    message: <div style={{ display: 'flex', flexDirection: 'row' }}><p style={{ padding: '0px' }}>Downloading Alert</p><Progress style={{ "marginLeft": "10px" }} type="circle" percent={percent} size={40} /></div>,
                    description: <div>{statuses.map((status, i) => <div key={i} style={{ display: 'flex', flexDirection: 'row' }}><p style={{ marginRight: '5px' }}>{status.file}</p>{status.status}</div>)}</div>,
                });
            }, 1);
        }
    }, [statuses, percent])

    useEffect(() => {
        if (alert) {
            dispatch(listVideos({ serial_number: alert.serial_number, title: alert.detection_date.replaceAll(':', '-') }))
                .then((response) => {
                    setVideos(response.payload.map((item, i) => [
                        `${i > 0 ? 'videoButton' : ''}`,
                        alert.serial_number,
                        alert.detection_date.replaceAll(':', '-'),
                        [item.text, item.file]
                    ]))
                })
        }
    }, [dispatch, alert])

    return (
        alert &&
        <div>
            {context}
            <Modal
                width="clamp(300px, 80vw, 800px)"
                open={open}
                footer={null}
                title={title}
                onCancel={handleClose}
            >
                <div className="cardDiv">
                    <Card className="cardLeft">
                        {
                            left_data.map((data, i) => <Typography.Paragraph key={i}><Text strong>{data[0]}</Text><Text>{data[1]}</Text></Typography.Paragraph>)
                        }
                    </Card>
                    {
                        width > 600 ? <Divider style={{height: `${100 + videos?.length * 45}px`}} type="vertical" /> : <Divider style={{margin: '0px'}} />
                    }
                    <Card className="cardRight">
                        {
                            role !== 'user' &&
                            <div style={{marginBottom: '3px'}}><b>Download Alert: </b> <DownloadButton
                                text='Download Alert'
                                dispatch={dispatch}
                                unit={alert.serial_number}
                                unitName={alert.vehicle_name}
                                detection={alert.detection_date.replaceAll(':', '-')}
                                setMessages={setStatuses}
                                setPercent={setPercent}
                                closeNotification={closeNotification}
                            /></div>
                        }
                        <b>Streams:</b>
                        <div>
                            {
                                videos ?
                                    videos.map((video, i) => {
                                        return <DetectionVideo key={i} className={video[0]} setUrl={setUrl} unit={video[1]} detection={video[2]} name={video[3]} open={open} />
                                    }) :
                                    ''
                            }
                        </div>
                    </Card>
                </div>
            </Modal>
        </div>
    )
}