import { DetectionModal } from "../../utility/components/DetectionModal";
import '../styles/latestAlert.scss'

export const LatestAlert = ({ open, setOpen, alert, setUrl }) => {
    const STR_TO_REPORT = {
        'None': 'Near Miss',
        'Manual': 'Manual',
        'Automatic': 'Automatic'
    }
    const INT_TO_HAZARD = {
        '0': 'No Hazard',
        '1': 'Lights',
        '2': 'Sirens'
    }

    return (
        <DetectionModal
            open={open}
            handleClose={() => setOpen(false)}
            title={<h2 className="h2">Latest Alert</h2>}
            left_data={[
                ['Vehicle Name: ', alert.vehicle_name],
                ['Serial Number: ', alert.serial_number],
                ['Time Occured: ', alert.latest_alert ? new Date(`${alert.latest_alert?.detection_date}+00:00`).toLocaleString() : 'No recent alert'],
                ['GPS: ', `${alert.latest_alert?.location.latitude}, ${alert.latest_alert?.location.longitude}`],
                ['Report: ', STR_TO_REPORT[alert.latest_alert?.trigger_type]],
                ['Hazard: ', INT_TO_HAZARD[alert.latest_alert?.trigger_level]],
            ]}
            alert={alert.latest_alert}
            setUrl={setUrl}
        />
    )
}