import React from "react";
import vid from "../../../videos/Icy_road_SR_1.MOV"

const Video = () => {
    return (
        <video autoPlay muted loop width={"100%"}>
            <source src={vid} type="video/mp4" />
        </video>
    );
}

export default Video