import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getSummary, getSummaryDates } from "../../store";
import { Button, Card, DatePicker, Divider, message, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { TableData } from "./components/TableData";
import './styles/alarm_history_summary.scss'
import { LOGIN_PAGE } from "../../configuration/paths";

const { Title } = Typography

export const AlarmHistorySummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = !!useSelector(state => state.app.auth.token);
    const location = useLocation();
    const [daily, setDaily] = useState([]);
    const [dailyData, setDailyData] = useState(null);
    const [weekly, setWeekly] = useState([]);
    const [weeklyData, setWeeklyData] = useState(null);
    const [monthly, setMonthly] = useState([]);
    const [monthlyData, setMonthlyData] = useState(null);
    const [changing, setChanging] = useState(undefined);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [validDates, setValidDates] = useState({});
    const [didPanelChange, setDidPanelChange] = useState({});

    const onChange = (date, duration, setChange, setTable) => {
        // setChange(undefined);
        let newDate = ''
        if (duration === 'daily') {
            newDate = new Date(date.format())
        } else if (duration === 'weekly') {
            const selectedDate = new Date(date.format())
            selectedDate.setDate(selectedDate.getDate() - selectedDate.getDay())
            newDate = selectedDate
        } else if (duration === 'monthly') {
            const selectedDate = new Date(date.format())
            selectedDate.setDate(1)
            newDate = selectedDate
        }

        dispatch(getSummary({ from_date: [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()], summary: duration }))
            .then((response) => {
                const payload = response.payload
                payload?.summary.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1)
                setTable([payload.from_date, json_to_html(payload, duration, payload.from_date)])
            })
    }

    const getDates = async (today, summaryType) => {
        return await dispatch(getSummaryDates({ year: today.getFullYear(), month: `00${today.getMonth() + 1}`.slice(-2), type: summaryType }))
            .then((response) => {
                return response.payload
            })
    }

    const setDates = (getDate, type, didPanelChange) => {
        getDates(getDate, type)
            .then((response) => {
                if (type === 'monthly') {
                    response = response.map((date) => {
                        date = date.split('-')
                        return `${date[0]}-${date[1]}`
                    })
                }
                setValidDates((vD) => {
                    return {
                        ...vD,
                        [type]: response
                    }
                })
            })
        setDidPanelChange((dPC) => {
            return {
                ...dPC,
                [type]: didPanelChange
            }
        })
    }

    const onOpenChange = (open, type) => {
        setChanging(type)
        if (open && (didPanelChange[type] === undefined || didPanelChange[type])) {
            setDates(new Date(), type, false)
        } else if (!open) {
            setChanging(undefined)
        }
    }

    const onPanelChange = (value, type) => {
        const typeToSummaryType = {
            'date': 'daily',
            'week': 'weekly',
            'month': 'monthly'
        }
        type = typeToSummaryType[type]
        setDates(value.toDate(), type, true)
    }

    const getDailyDuration = (date) => {
        const fromDate = new Date(`${date}T00:00:00`)
        const toDate = new Date(new Date(`${date}T00:00:00`).setDate(fromDate.getDate() + 1))
        return `${fromDate.toDateString()} - ${toDate.toDateString()}`
    }

    const getWeekDuration = (date) => {
        const fromDate = new Date(`${date}T00:00:00`)
        const toDate = new Date(new Date(`${date}T00:00:00`).setDate(fromDate.getDate() + 7))
        toDate.setDate(toDate.getDate())
        return `${fromDate.toDateString()} - ${toDate.toDateString()}`
    }

    const getMonthDuration = (date) => {
        const fromDate = new Date(`${date}T00:00:00`)
        const toDate = new Date(new Date(`${date}T00:00:00`).setMonth(fromDate.getMonth() + 1))
        toDate.setDate(toDate.getDate())
        return `${fromDate.toDateString()} - ${toDate.toDateString()}`
    }

    const duration_to_date_filter = (duration, date) => {
        if (duration === 'daily') {
            return getDailyDuration(date)
        } else if (duration === 'weekly') {
            return getWeekDuration(date)
        } else if (duration === 'monthly') {
            return getMonthDuration(date)
        }
    }

    const json_to_arr = (json, duration) => {
        let data = [['Unit', 'Near Miss', 'Tablet', 'Manual', 'Automatic']]
        if (json.is_special) {
            data[0] = data[0].concat('Debug')
        }
        data[0] = data[0].concat(['No Hazard', 'Lights', 'Sirens', 'Total'])
        data[0] = data[0].join(',')
        if (json.summary) {
            for (let unit of json.summary) {
                let row = [
                    unit.vehicle_name,
                    unit.fa_nm,
                    unit.tablet,
                    unit.manual,
                    unit.automatic
                ]
                if (json.is_special) {
                    row = row.concat(unit.debug)
                }
                row = row.concat([
                    unit.no_hazard,
                    unit.lights,
                    unit.sirens,
                    unit.total
                ])
                data = data.concat([row.join(',')])
            }
        }
        if (duration === 'daily') {
            setDailyData(data.join('\n'))
        } else if (duration === 'weekly') {
            setWeeklyData(data.join('\n'))
        } else if (duration === 'monthly') {
            setMonthlyData(data.join('\n'))
        }
    }

    const json_to_html = (json, duration, fromDate) => {
        json_to_arr(json, duration)
        return (
            <div>
                <table className="dataTable">
                    <thead>
                        <tr>
                            <th className="tableData">Unit</th>
                            <th className="tableData">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            json.summary?.map((unit) => {
                                return (
                                    <tr key={unit.vehicle_name} className="tableRow">
                                        <TableData className="tableData" text={unit.vehicle_name} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate) }} text={unit.total} />
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <h3><u>Report Types</u></h3>
                <table className="dataTable">
                    <thead>
                        <tr>
                            <th className="tableData">Unit</th>
                            <th className="tableData">Near Miss</th>
                            <th className="tableData">Tablet</th>
                            <th className="tableData">Manual</th>
                            <th className="tableData">Automatic</th>
                            {json.is_special && <th className="tableData">Debug</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            json.summary?.map((unit) => {
                                return (
                                    <tr key={unit.vehicle_name} className="tableRow">
                                        <TableData className="tableData" text={unit.vehicle_name} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), type: "FA/NM" }} text={unit.fa_nm} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), type: "Tablet" }} text={unit.tablet} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), type: "Manual" }} text={unit.manual} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), type: "Automatic" }} text={unit.automatic} />
                                        {json.is_special && <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), type: "Debug" }} text={unit.debug} />}
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
                <h3><u>Hazard Levels</u></h3>
                <table className="dataTable">
                    <thead>
                        <tr>
                            <th className="tableData">Unit</th>
                            <th className="tableData">No Hazard</th>
                            <th className="tableData">Lights</th>
                            <th className="tableData">Sirens</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            json.summary?.map((unit) => {
                                return (
                                    <tr key={unit.vehicle_name} className="tableRow">
                                        <TableData className="tableData" text={unit.vehicle_name} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), level: "0" }} text={unit.no_hazard} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), level: "1" }} text={unit.lights} />
                                        <TableData className="tableData data" navigate={navigate} parameters={{ name: unit.vehicle_name, unit: unit.serial_number, date: duration_to_date_filter(duration, fromDate), level: "2" }} text={unit.sirens} />
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const dateButton = (string, setChange, report) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "5px" }}>
                <Button
                    onClick={e => {
                        setChange(report)
                    }}
                >
                    {string}
                </Button>
                <Button
                    icon={<DownloadOutlined />}
                    onClick={() => download(report)}
                >
                    Download CSV
                </Button>
            </div>
        )
    }

    const disabledDate = (value) => {
        const month = `00${value.toDate().getMonth() + 1}`.slice(-2)
        let date = `00${value.toDate().getDate()}`.slice(-2)
        date = `${value.toDate().getFullYear()}-${month}-${date}`
        return !validDates?.daily?.includes(date)
    }

    const disabledWeek = (value) => {
        let date = new Date(value.toDate().setDate(value.toDate().getDate() - value.toDate().getDay()))
        const month = `00${date.getMonth() + 1}`.slice(-2)
        const day = `00${date.getDate()}`.slice(-2)
        date = `${date.getFullYear()}-${month}-${day}`
        return !validDates?.weekly?.includes(date)
    }

    const disabledMonth = (value) => {
        const month = `00${value.toDate().getMonth() + 1}`.slice(-2)
        const date = `${value.toDate().getFullYear()}-${month}`
        return !validDates?.monthly?.includes(date)
    }

    const download = (report) => {
        const blob = report === 'daily' ? new Blob([dailyData], { type: 'text/csv' }) : report === 'weekly' ? new Blob([weeklyData], { type: 'text/csv' }) : new Blob([monthlyData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${report === 'daily' ? `${daily[0]}_daily` : report === 'weekly' ? `${weekly[0]}_weekly` : `${monthly[0]}_monthly`}_report.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        message.info(`Downloaded the ${report} report`)
    }

    const downloadAll = () => {
        download('daily')
        download('weekly')
        download('monthly')
    }

    useEffect(() => {
        if (!loggedIn) {
            navigate(LOGIN_PAGE)
        } else {
            dispatch(getSummary({ summary: 'daily' }))
                .then((response) => {
                    const payload = response.payload
                    payload?.summary.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1)
                    setDaily([payload.from_date, json_to_html(payload, 'daily', payload.from_date)])
                })
            dispatch(getSummary({ summary: 'weekly' }))
                .then((response) => {
                    const payload = response.payload
                    payload?.summary.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1)
                    setWeekly([payload.from_date, json_to_html(payload, 'weekly', payload.from_date)])
                })
            dispatch(getSummary({ summary: 'monthly' }))
                .then((response) => {
                    const payload = response.payload
                    payload?.summary.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1)
                    setMonthly([payload.from_date, json_to_html(payload, 'monthly', payload.from_date)])
                })
        }
    }, [loggedIn])

    useEffect(() => {
        if (daily.length && weekly.length && monthly.length) {
            setPageLoaded(true)
        }
    }, [daily, weekly, monthly])

    useEffect(() => {
        if (pageLoaded) {
            const params = new URLSearchParams(location.search);
            const report = params.get('report')
            let from_date = params.get('from')?.split('/')
            if (from_date && report) {
                from_date = [Number(from_date[0]), Number(from_date[1]), Number(from_date[2])]
                const setTable = report === 'daily' ? setDaily : report === 'weekly' ? setWeekly : setMonthly
                dispatch(getSummary({ from_date: from_date, summary: report }))
                    .then((response) => {
                        const payload = response.payload
                        payload?.summary.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1)
                        setTable([payload.from_date, json_to_html(payload, report, payload.from_date)])
                    })
            }
        }
    }, [pageLoaded])

    return (
        <div>
            <Button style={{ marginLeft: '5px', marginBottom: '5px' }} icon={<DownloadOutlined />} onClick={downloadAll} disabled={changing}>Download All CVSs</Button>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
                <Card>
                    <Title>Daily</Title>
                    {
                        changing === 'daily' ?
                            <DatePicker
                                disabledDate={disabledDate}
                                onChange={(date, dateString) => onChange(date, 'daily', setChanging, setDaily)}
                                onOpenChange={(open) => onOpenChange(open, 'daily')}
                                onPanelChange={onPanelChange}
                            /> :
                            dateButton(daily[0] ? new Date(`${daily[0]}T00:00:00`).toDateString() : "Pick a day", setChanging, 'daily')
                    }

                    <Divider />
                    {
                        daily[1]
                    }
                </Card>
                <Card>
                    <Title>Weekly</Title>
                    {
                        changing === 'weekly' ?
                            <DatePicker
                                disabledDate={disabledWeek}
                                onChange={(date, dateString) => onChange(date, 'weekly', setChanging, setWeekly)}
                                onOpenChange={(open) => onOpenChange(open, 'weekly')}
                                onPanelChange={onPanelChange} picker="week"
                            /> :
                            dateButton(weekly[0] ? getWeekDuration(weekly[0]) : "Pick a week", setChanging, 'weekly')
                    }
                    <Divider />
                    {
                        weekly[1]
                    }
                </Card>
                <Card>
                    <Title>Monthy</Title>
                    {
                        changing === 'monthly' ?
                            <DatePicker
                                disabledDate={disabledMonth}
                                onChange={(date, dateString) => onChange(date, 'monthly', setChanging, setMonthly)}
                                onOpenChange={(open) => onOpenChange(open, 'monthly')}
                                onPanelChange={onPanelChange}
                                picker="month"
                            /> :
                            dateButton(monthly[0] ? getMonthDuration(monthly[0]) : "Pick a month", setChanging, 'monthly')
                    }
                    <Divider />
                    {
                        monthly[1]
                    }
                </Card>
            </div>
        </div>
    )
}