import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { getAlert } from "../../../store";
import "../styles/video_container.scss"

const DetectionVideo = ({name, setUrl, className, detection, unit}) => {
    const dispatch = useDispatch();
    const [videoUrl, setVideoUrl] = useState('');

    const handleClick = () => {
        setUrl([name[0], videoUrl])
    }

    useEffect(() => {
        const key = `${unit}/${detection}/${name[1]}`
        dispatch(getAlert(key))
        .then((response) => {
            setVideoUrl(response.payload)
        })
    }, [unit, detection, name[1]])

    return (
        <div>
            {
                videoUrl !== '' &&
                <div className={className} onClick={handleClick}>
                        <Button className="video-button">{name[0]}</Button>
                </div>
            }
        </div>
    )
}

export default DetectionVideo;
