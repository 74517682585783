import React, { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import { useLocation, useNavigate } from 'react-router';
import sirens from './imgs/marker-icon-red.png'
import lights from './imgs/marker-icon-yellow.png'
import note from './imgs/marker-icon-green.png'
import selected_sirens from './imgs/selected-red.png'
import selected_lights from './imgs/selected-yellow.png'
import selected_note from './imgs/selected-green.png'

const AlertMarker = ({serialNumber, level, alert, selectedCounter, setSelectedCounter, counter, mapLocation, setMapLocation}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [icon, setIcon] = useState(null);

    const getIcon = (green, yellow, red) => {
        return new Icon({
            iconUrl: level ? level === 1 ? yellow : red : green,
            iconAnchor: [15, 15],
            iconSize: [30, 30]
        })
    }

    useEffect(() => {
        setIcon(getIcon(note, lights, sirens))
    }, [])

    useEffect(() => {
        if (selectedCounter === counter) {
            setIcon(getIcon(selected_note, selected_lights, selected_sirens))
        } else {
            setIcon(getIcon(note, lights, sirens))
        }
    }, [selectedCounter])

    return (
        <div>
            {
                !!mapLocation.latitude && !!mapLocation.longitude && icon ? 
                <Marker
                    eventHandlers={{
                        click: (e) => {
                            setSelectedCounter(counter)
                            setMapLocation([mapLocation.latitude, mapLocation.longitude])
                            navigate(`${location.pathname}?unit=${serialNumber}&alert=${alert}`)
                        }
                    }}
                    position={[mapLocation.latitude, mapLocation.longitude]}
                    icon={icon}
                /> : ''
            }
        </div>
    )
}

export default AlertMarker;