import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { getAlert, getDownloadFiles } from "../../../store";
import JSZip, { files } from 'jszip';
import { AvailableFilesList } from "./AvailableFilesList";


export const DownloadButton = ({ unit, detection, dispatch, unitName, text, setMessages, setPercent, closeNotification }) => {
    const [counter, setCounter] = useState(-1);
    const [filesAvailable, setFilesAvailalbe] = useState([]);
    const [filesWanted, setFilesWanted] = useState([]);
    const [open, setOpen] = useState(false);
    const [zip, setZip] = useState(new JSZip());



    const getFiles = () => {
        dispatch(getDownloadFiles({ serial_number: unit, title: detection }))
            .then((response) => {
                setFilesAvailalbe(response.payload)
            })
            .catch(() => {
                setFilesAvailalbe([])
            })
    }

    const download = async () => {
        const getFile = async (prefix, file) => {
            setMessages((m) => m.concat({ file: file, status: <LoadingOutlined /> }))
            return dispatch(getAlert(prefix))
                .then((response) => {
                    if (response.payload === '') {
                        setMessages((m) => m.map((message) => {
                            if (message.file === file) {
                                return { file: file, status: <CloseCircleTwoTone twoToneColor="#eb2f96" /> }
                            } else {
                                return message
                            }
                        }))
                        setCounter(c => c + 1)
                    } else {
                        fetch(response.payload)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.blob();
                            })
                            .then((blobData) => {
                                zip.file(file, blobData)
                                setMessages((m) => m.map((message) => {
                                    if (message.file === file) {
                                        return { file: file, status: <CheckCircleTwoTone twoToneColor="#52c41a" /> }
                                    } else {
                                        return message
                                    }
                                }))
                                setCounter(c => c + 1)
                            })

                    }
                })
        }

        setCounter(0)
        filesWanted.forEach((file) => getFile(`${unit}/${detection}/${file}`, file, unitName.replaceAll(" ", "_")))
        setFilesAvailalbe([])
    }

    useEffect(() => {
        if (filesAvailable.length > 4) {
            setOpen(true)
        } else if (filesAvailable.length > 0) {
            setFilesWanted(filesAvailable)
        } else {
            setOpen(false)
        }
    }, [filesAvailable])

    useEffect(() => {
        if (filesWanted.length > 0) {
            download()
        }
    }, [filesWanted])

    useEffect(() => {
        if (counter >= 0) {
            setPercent(Math.floor(counter / filesWanted.length * 100))
            if (counter === filesWanted.length) {
                setMessages((m) => m.concat({ file: 'Putting all the files in a folder', status: <LoadingOutlined /> }))
                zip.generateAsync({ type: 'blob' })
                    .then((response) => {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(response);
                        downloadLink.download = `${unitName.replaceAll(" ", "_")}_${detection}.zip`;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        setZip(new JSZip())
                        setCounter(-1)
                        closeNotification()
                    })
            }
        }
    }, [counter])

    return (
        <div>
            <Button onClick={getFiles} disabled={counter > 0} icon={<DownloadOutlined />}>{text}</Button>
            {filesAvailable.length > 4 && <AvailableFilesList files={filesAvailable} setFilesWanted={setFilesWanted} open={open} setOpen={setOpen} />}
        </div>
    )
}
