import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const FilterButton = ({ value, setFilter, setSelectedKeys }) => {
    const removeFilter = () => {
        // Use indexOf() to get the threat level
        const STR_TO_INT = ['No Hazard', 'Lights', 'Sirens']

        setSelectedKeys(selectedKeys => selectedKeys.filter(k => {
            if (k[0].length === 2) {
                return k[0][0] !== value[0] && k[0][1] !== value[1]
            }
            return k[0] !== value
        }))

        setFilter(f => f.filter((val) => {
            if (STR_TO_INT.indexOf(value) !== -1) {
                return val !== STR_TO_INT.indexOf(value)
            } else {
                return val !== value
            }
        }))
    }

    const style = {
        borderColor: 'black',
        marginLeft: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        display: 'flex',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const button = value.length === 2 ?
        <Button
            style={{ ...style, height: '50px', paddingTop: '22px' }}
            icon={<CloseOutlined style={{ color: 'red' }} />}
            onClick={removeFilter}
            type='text'
        >
            <p>{value[0]}<br />{value[1]}</p>
        </Button> :
        <Button
            style={style}
            icon={<CloseOutlined style={{ color: 'red' }} />}
            onClick={removeFilter}
            type='text'
        >
            {value}
        </Button>

    return button
}
