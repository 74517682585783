import { useEffect, useState } from "react";
import { AutoComplete, Button, Divider, Typography } from "antd"

const { Title } = Typography;

export const VehicleSection = ({ configuration, vehicles, tempConfig, setTempConfig, action, setAction, setManaging, selectedUser, setSave, userVehicleOptionsRef }) => {
    const [sortedVehicle, setSortedVehicles] = useState([])
    const [autoValue, setAutoValue] = useState('')

    const getAvailableVehicles = () => {
        let unused_vehicles = []
        for (let vehicle of vehicles) {
            if (tempConfig.vehicles.indexOf(vehicle) === -1) {
                unused_vehicles = [...unused_vehicles, { value: vehicle }]
            }
        }
        return unused_vehicles.length ? unused_vehicles : [{ value: 'No vehicles left' }]
    }

    useEffect(() => {
        setSortedVehicles(tempConfig.vehicles?.sort((a, b) => a > b ? 1 : -1))
    }, [tempConfig])

    return (
        <div>
            <div className="groups">
                <div className="buttons" ref={userVehicleOptionsRef}>
                    <Title className="title" level={4}>Options</Title>
                    <Button
                        className="button"
                        disabled={!configuration[selectedUser]?.name || action}
                        onClick={() => {
                            setManaging('Users')
                            setAction('addUserVehicle')
                            setTempConfig({
                                ...configuration[selectedUser],
                                vehicles: [...configuration[selectedUser].vehicles, '']
                            })
                        }}
                    >
                        Assign Vehicle
                    </Button>
                    <Button
                        className="button"
                        disabled={!configuration[selectedUser]?.name || action || !configuration[selectedUser]?.vehicles.length}
                        onClick={() => {
                            setManaging('Users')
                            setAction('removeUserVehicle')
                        }}
                    >
                        Remove Vehicle
                    </Button>
                    <Button
                        className="button"
                        disabled={action !== 'addUserVehicle' && action !== 'removeUserVehicle'}
                        onClick={() => {
                            setTempConfig(configuration[selectedUser])
                            setAction('')
                        }}
                    >
                        Cancel
                    </Button>
                </div>
                <Divider className="divider" type="vertical" />
                <div>
                    <Title level={4}>Vehicles</Title>
                    <div className="list">
                        {
                            selectedUser > -1 ?
                                tempConfig?.vehicles?.length ?
                                    <ul className="listConfig">
                                        {
                                            sortedVehicle?.map((child, i) => {
                                                return <li
                                                    className={`configItem ${action === 'removeUserVehicle' ? 'configItemDelete' : ''}`}
                                                    onClick={() => {
                                                        if (action === 'removeUserVehicle') {
                                                            setSave([tempConfig.name, child])
                                                        }
                                                    }}
                                                    key={i}
                                                >
                                                    {
                                                        child === '' ?
                                                            <AutoComplete
                                                                style={{
                                                                    width: 200,
                                                                }}
                                                                allowClear
                                                                backfill
                                                                options={getAvailableVehicles()}
                                                                placeholder='Select vehicle'
                                                                value={autoValue}
                                                                onChange={(value, option) => {
                                                                    setAutoValue(value)
                                                                }}
                                                                filterOption={(inputVal, option) =>
                                                                    option.value.toUpperCase().indexOf(inputVal.toUpperCase()) !== -1
                                                                }
                                                                onSelect={(value, option) => {
                                                                    if (value !== 'No vehicles left') {
                                                                        setSave([configuration[selectedUser].name, value])
                                                                    }
                                                                    setAutoValue('')
                                                                }}
                                                            /> :
                                                            child
                                                    }
                                                </li>
                                            })
                                        }
                                    </ul> :
                                    <p className="subgroupText">There are currently no vehicles assigned to this user.</p> :
                                ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}