import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router"
import { useSelector } from "react-redux";
import { ACCOUNT_PAGE, LOGIN_PAGE, SETTINGS_PAGE } from "../../configuration/paths";
import { useDispatch } from "react-redux";
import { sendAccountInfo, validateToken, verifyUsername } from "../../store";
import { Button, Card, Divider, Form, Input, Typography } from "antd";
import { SubmitToken } from "./components/SubmitToken";

const { Title } = Typography;

export const NewAccount = ({ minLength = 8, minLower = 1, minUpper = 1, minNum = 1, minSpecial = 1 }) => {
    const token = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [successSubmit, setSuccessSubmit] = useState(false);

    const [originalUsername, setOriginalUsername] = useState('');
    const [curUsername, setCurUsername] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [usernameError, setUsernameError] = useState(undefined);
    const [emailError, setEmailError] = useState('Please input an email address!');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const loggedIn = useSelector(state => state.app.auth.token)

    const validEmail = (_, email) => {
        const splitAddress = email.split('@')
        if (splitAddress.length === 2 && splitAddress[0].length > 0 && splitAddress[1].length > 0) {
            const splitDomain = splitAddress[1].split('.')
            if (splitDomain.length >= 2) {
                for (let part of splitDomain) {
                    if (part.length === 0) {
                        setEmailError("Please enter a valid email address!")
                        return Promise.reject(new Error("Please enter a valid email address!"))
                    }
                }
                return Promise.resolve();
            }
        }
        setEmailError("Please enter a valid email address!")
        return Promise.reject(new Error("Please enter a valid email address!"))
    }

    const validePhoneNumber = (_, number) => {
        if (!number) {
            return Promise.resolve()
        } else {
            if (isFinite(number) && (number.length === 10 || number.length === 0)) {
                return Promise.resolve()
            } else {
                return Promise.reject("Phone number needs to be 10 digits long.")
            }
        }
    }

    const onFinish = (values) => {
        dispatch(sendAccountInfo({ ...values, original_username: originalUsername }))
            .then((response) => {
                if (response.payload.status === 202) {
                    setPasswordErrors(response.payload.data.errors)
                } else if (response.payload.status === 200) {
                    setSuccessSubmit(true)
                    setPasswordErrors([])
                }
            })
    }

    useEffect(() => {
        if (loggedIn) {
            navigate(ACCOUNT_PAGE)
        }
    }, [loggedIn])

    useEffect(() => {
        if (token.token) {
            dispatch(validateToken({ ...token, check_token: true }))
                .then((response) => {
                    response = response.payload
                    if (response.status === 202) {
                        if (response.data === 'Invalid token') {
                            navigate(LOGIN_PAGE)
                        } else if (response.data === 'Token Expired') {
                            navigate('/create_account')
                        }
                    } else if (response.status === 200) {
                        setOriginalUsername(response.data)
                        setCurUsername(response.data)
                        setNewUsername(response.data)
                    }
                })
        }
    }, [token])

    useEffect(() => {
        form.setFieldsValue({ username: newUsername });
    }, [newUsername, form])

    return (
        <Card
            style={{ maxWidth: '1000px', margin: '0 auto' }}
        >
            {
                !token.token ?
                    <SubmitToken dispatch={dispatch} form={form} navigate={navigate} /> :
                    !successSubmit ?
                        <div>
                            <Title>Setting Up New Account</Title>
                            <Divider />
                            <Form
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    validateTrigger='onBlur'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input a username!',
                                        },
                                    ]}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Input
                                            style={{ maxWidth: "250px" }}
                                            value={newUsername}
                                            onChange={(e) => setNewUsername(e.target.value)}
                                        />
                                        <Button
                                            style={{ marginLeft: '10px' }}
                                            disabled={curUsername === newUsername || newUsername === '' || newUsername === usernameError}
                                            onClick={() => {
                                                if (newUsername === originalUsername) {
                                                    setCurUsername(newUsername)
                                                    setUsernameError('')
                                                } else {
                                                    setNewUsername('')
                                                    dispatch(verifyUsername({ username: newUsername }))
                                                        .then((response) => {
                                                            if (response.payload.status === 200) {
                                                                if (!response.payload.data) {
                                                                    setUsernameError(newUsername)
                                                                } else {
                                                                    setCurUsername(newUsername)
                                                                    setUsernameError('')
                                                                }
                                                                setNewUsername(newUsername)
                                                            }
                                                        })
                                                }
                                            }}
                                        >
                                            Check Availability
                                        </Button>
                                        {
                                            (newUsername === usernameError) && <p style={{ margin: '0', marginLeft: '10px', color: 'red', alignSelf: 'center' }}>That username has already been taken.</p>
                                        }
                                    </div>
                                </Form.Item>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Form.Item
                                        label='First Name'
                                        name='firstName'
                                    >
                                        <Input
                                            style={{ width: "95%" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label='Last Name'
                                        name='lastName'
                                    >
                                        <Input
                                            style={{ width: "95%" }}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    // validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: emailError,
                                            validator: validEmail
                                        }
                                    ]}
                                >
                                    <Input
                                        style={{ maxWidth: "250px" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Phone Number"
                                    name='phoneNumber'
                                    rules={[
                                        { validator: validePhoneNumber }
                                    ]}
                                >
                                    <Input
                                        type="tel"
                                        style={{ maxWidth: "250px" }}
                                        maxLength={10}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input a password!'
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        style={{ maxWidth: "250px" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please re-enter password'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        style={{ maxWidth: "250px" }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        disabled={curUsername !== newUsername && newUsername !== originalUsername}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                            <Divider />
                            <div>
                                <Title level={4} className="p">Your password needs to have all the following requirements:</Title>
                                <ul>
                                    <li className="li">A minimum of {minLength} characters</li>
                                    <li className="li">A minimum of {minLower} lowercase characters</li>
                                    <li className="li">A minimum of {minUpper} uppercase characters</li>
                                    <li className="li">A minimum of {minNum} numbers</li>
                                    <li className="li">
                                        A minimum of {minSpecial} special characters
                                        <ul>
                                            <li className="li">{`! # $ % & ( ) * + , - . < = > ? @ ^ _ | ~`}</li>
                                        </ul>
                                    </li>

                                </ul>
                                {
                                    passwordErrors.length > 0 &&
                                    <div>
                                        <Title level={4} className="p">Password Errors:</Title>
                                        <ul>
                                            {passwordErrors.map((error) => {
                                                return <li style={{ color: 'red' }}>
                                                    {error}
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div> :
                        <div>
                            <p>Thank you for your time to finish setting up your account.</p>
                            <p>You can always change any of these settings by going to your
                                <a href="" onClick={() => navigate(SETTINGS_PAGE)}> profile settings</a> after
                                <a href="" onClick={() => navigate(LOGIN_PAGE)}> logging in</a>.</p>
                        </div>
            }
        </Card>
    )
}