import React, { useState } from "react";
import { Button, Card, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TimeBlock } from "./TimeBlock";
import { InfoCircle } from "../../utility/components/InfoCircle";
import "../styles/timeBlockMenu.scss"

const { Title } = Typography;

export const TimeBlockMenu = ({
    timeBlocks,
    setTimeBlocks,
    setSave,
    counter,
    setCounter
}) => {
    const [newTimeBlock, setNewTimeBlock] = useState(false);

    const makeNewTimeBlock = () => {
        setSave(true)
        setNewTimeBlock(true)
        setTimeBlocks(
            [...timeBlocks,
            {
                id: counter,
                day: '',
                timeInterval: ['00:00:00', '00:00:00'],
                edit: true,
                newBlock: true,
                blockUpdate: false,
                toDelete: false,
                enabled: true
            }]
        );
        setCounter(c => c + 1);
    }

    return (
        <div className="menuDiv">
            <div className="timeblockTitle">
                <Title level={4}>Time Blocks</Title>
                <div className="infoCircleOffset">
                    <InfoCircle
                        info={
                            <div>
                                <p>This is used for immediate alert notifications. You will need at least one of these to get immediate alerts.</p>
                                <p>
                                    <b>Note:</b> In order to update the time, you need to click on "OK" when you put in both time. Not clicking on "OK"
                                    will result in the time not updating.
                                </p>
                            </div>
                        }
                    />
                </div>
            </div>
            <Card className="menuCard">
                {
                    timeBlocks ?
                        timeBlocks.map((tB) => {
                            return <TimeBlock
                                key={tB.id}
                                newTimeBlocks={timeBlocks}
                                setNewTimeBlocks={setTimeBlocks}
                                setNewTimeBlock={setNewTimeBlock}
                                timeBlock={tB}
                                setSave={setSave}
                            />
                        }) :
                        ''
                }
            </Card>
            <Button
                title="Add New"
                icon={<PlusOutlined />}
                onClick={() => makeNewTimeBlock()}
                disabled={newTimeBlock}
            />
        </div>
    )
}