
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Card, Carousel } from "antd";
import group1 from "./assets/group1.svg";
import rectangle4 from "./assets/rectangle4.svg";
import CardFrame from "./components/CardFrame";
import { ContactForm } from "./ContactForm";
import Video from "./components/Video";
import { PRIVACY_PAGE } from "../../configuration/paths";
import { getLandingVideos } from "../../store";
import { useDispatch } from "react-redux";
import "./styles/landing.scss";
import { propsData } from './constants/landing_constants.js'

const Index = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vid_cont = document.querySelector('.video-container')
    const init_width = window.innerWidth
    const [width, setWidth] = useState(init_width);
    const [vwidth, setVWidth] = useState(vid_cont ? vid_cont.offsetWidth : init_width > 500 ? init_width * .67 : 0);
    const [videos, setVideos] = useState([])

    useEffect(() => {
        dispatch(getLandingVideos())
            .then((response) => {
                setVideos(response.payload)
            })

        const handleResize = () => {
            const windowWidth = window.innerWidth
            let vidCont = document.querySelector('.video-container')
            vidCont = vidCont === null ? document.querySelector('.video-container-mobile') : vidCont
            setWidth(windowWidth)
            setVWidth(vidCont.offsetWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div className="mac-book-air-1">
            <div className="protect-whats">Protect what's</div>
            <hr className={`rectangle-13`} src={rectangle4} />
            <div className="important">Important</div>
            <div className={`video-container${width < 770 ? '-mobile' : ''}`}>
                <div>
                    <Video />
                </div>
                <div className='frame-5' style={width >= 770 ? { marginLeft: `-${vwidth - 75}px` } : {}}>
                    <div className="num-3000">3,000 </div>
                    <div className="fatalities-per-year">
                        work zone fatalities per year due to distracted driving
                    </div>
                </div>
                <div className='frame-6' style={width >= 770 ? { marginRight: `-${vwidth - 80}px` } : {}}>
                    <div className="num-1-in-5">1 in 5 </div>
                    <div className="were-pedestrians-or">
                        were pedestrians or workers; not motorists or passengers
                    </div>
                </div>
            </div>
            <div className="roadz_logo">
                <img className="group" src={group1} alt="The Roadz Logo" />
                <div className="roadside-active-defe">Roadside Active Defense Zone</div>
            </div>
            <div className={`defense${width < 1300 ? '-mobile' : ''}`}>
                <div className={`header`}>What is active defense?</div>
                <div className={`flex-container`}>
                    <div className="num">01</div>
                    <div className="info">
                        Actively prevent accidents with 24/7 vigilence powered by AI,
                        Radar and Computer vision
                    </div>
                </div> 
                <div className="flex-container">
                    <div className="num">02</div>
                    <div className="info">
                        Automated visual alerts for drivers
                    </div>
                </div>
                <div className="flex-container">
                    <div className="num">03</div>
                    <div className="info">
                        Automated acoustic alerts for workers and bystanders
                    </div>
                </div>
            </div>
            <div className="group-4">
                {
                    videos.length ?
                        <Carousel autoplay autoplaySpeed={10000}>
                            {
                                videos.map((video, i) => {
                                    return (
                                        <div key={i} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <video key={video} height={width < 1600 ? 300 : 800} autoPlay muted loop width={"100%"}>
                                                <source src={video} type="video/mp4" />
                                            </video>
                                        </div>
                                    )
                                })
                            }
                        </Carousel> : 'Loading Videos...'
                }
            </div>
            <div className="how-does-it-work">How does it work?</div>
            <div className={`how-it-works${width < 1600 ? '-mobile' : ''}`}>
                <div className="flex-container">
                    <CardFrame className="frame-instance" {...propsData.frame8} />
                    <CardFrame className="frame-instance" {...propsData.frame9} />
                </div>
                <div className="flex-container">
                    <CardFrame className="frame-instance" {...propsData.frame10} />
                    <CardFrame className="frame-instance" {...propsData.frame11} />
                </div>
                <div className="flex-container">
                    <CardFrame className="frame-instance" {...propsData.frame12} />
                    <CardFrame className="frame-instance" {...propsData.frame13} />
                </div>
            </div>

            <Card style={{ width: '65%' }}>
                <ContactForm />
            </Card>
            <a className="privacy" onClick={() => navigate(PRIVACY_PAGE)}>Privacy Policy</a>
        </div>
    );
};

export default Index;