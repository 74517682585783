import { useEffect, useState } from "react";
import { Typography } from "antd";
import { UserSection } from "./sections/UserSection";
import { VehicleSection } from "./sections/VehicleSection";

const { Title } = Typography;

export const UsersConfig = ({ editorRole, configuration, action, setAction, setManaging, userLicences, setSave, refs, usernameInfo }) => {
    const [selectedUser, setSelectedUser] = useState(-1);
    const [tempConfig, setTempConfig] = useState({});
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (action === 'createUser') {
            setTitle('Creating a new user')
        } else if (action === 'removeUser') {
            setTitle('Select a user to delete')
        } else if (selectedUser !== -1) {
            setTitle(configuration.Users[selectedUser].name)
        } else {
            setTitle('Select a User to manage.')
        }
    }, [selectedUser, action])

    useEffect(() => {
        if (action !== 'removeUser' && action !== 'changeRole') {
            setAction('')
        }
    }, [selectedUser])

    useEffect(() => {
        selectedUser > -1 && selectedUser < configuration.Users.length && setTempConfig(configuration.Users[selectedUser])
        if (action === 'removeUser' && selectedUser > -1 && selectedUser < configuration.Users.length) {
            setSave(configuration.Users[selectedUser].name)
            setSelectedUser(-1)
        }
    }, [configuration, selectedUser])

    return (
        <div className="manageBox">
            <Title level={2} style={{ textDecoration: 'underline' }}>User Management</Title>
            <Title
                level={2}
                style={{ margin: '0', marginLeft: '15px', marginBottom: '15px' }}
            >
                {title}
            </Title>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <UserSection
                    editorRole={editorRole}
                    configuration={configuration.Users}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    action={action}
                    setAction={setAction}
                    setManaging={setManaging}
                    userLicences={userLicences}
                    usernameInfo={usernameInfo}
                    setSave={setSave}
                    refs={refs}
                />
                {/* <VehicleSection
                    configuration={configuration.Users}
                    selectedUser={selectedUser}
                    vehicles={configuration.Vehicles.map((vehicle) => vehicle.name)}
                    action={action}
                    setAction={setAction}
                    tempConfig={tempConfig}
                    setTempConfig={setTempConfig}
                    setSave={setSave}
                    setManaging={setManaging}
                /> */}
            </div>
        </div>
    )
}