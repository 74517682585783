import { Card, Form, Typography, Button, Input, Divider } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import { resetPassword, resetPasswordRequest } from "../../store";
import { ACCOUNT_PAGE, LOGIN_PAGE, PASSWORD_RESET_REQUEST } from "../../configuration/paths";
import { validatePassword } from "../utility/scripts/validate_password";
import './styles/index.scss'

const { Title, Text } = Typography;

export const ForgotPassword = ({ minLength = 8, minLower = 1, minUpper = 1, minNum = 1, minSpecial = 1 }) => {
    const dispatch = useDispatch();
    const token = useParams();
    const navigate = useNavigate();
    const form = useFormInstance();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [submitErrors, setSubmitErrors] = useState([])
    const [responseMessage, setResponseMessage] = useState(undefined);
    const [retryTimer, setRetryTimer] = useState(0);
    const loggedIn = useSelector(state => state.app.auth.token)

    const errorMessages = {
        400: "Request failed with status code 400",
        401: "Request failed with status code 401",
        404: "Request failed with status code 404",
        406: "Request failed with status code 406",
    }

    useEffect(() => {
        if (loggedIn) {
            navigate(ACCOUNT_PAGE)
        }
    }, [loggedIn])

    useEffect(() => {
        const interval = setInterval(() => {
            if (retryTimer > 0) {
                setRetryTimer(num => num - 1)
            }
        }, 1000)

        return () => clearInterval(interval)
    })

    const initStates = () => {
        setEmail('');
        setNewPassword('');
        setRepeatPassword('');
    }

    const validEmailSyntax = (email) => {
        const splitAddress = email.split('@')
        if (splitAddress.length === 2 && splitAddress[0].length > 0 && splitAddress[1].length > 0) {
            const splitDomain = splitAddress[1].split('.')
            if (splitDomain.length >= 2) {
                for (let part of splitDomain) {
                    if (part.length === 0) {
                        return false
                    }
                }
                return true
            }
        }
        return false
    }

    const handleSubmit = () => {
        setResponseMessage(<p>If we can find an account associated with the email {email}, then an email will be sent containing a link to reset your password</p>)
        dispatch(resetPasswordRequest(email))
        setRetryTimer(30)
    }

    const submitNewPassword = async () => {
        let errors = validatePassword(newPassword, minLength, minLower, minUpper, minNum, minSpecial)
        if (errors.length === 0 && newPassword === repeatPassword) {
            await dispatch(resetPassword({ token: token.token, new_password: newPassword }))
                .then((response) => {
                    if (response.payload?.status === 200) {
                        setResponseMessage(<p>Your password has succuessfully been changed. Continue to <a onClick={() => navigate(LOGIN_PAGE)}>log in</a>.</p>)
                    } else if (response.error.message == errorMessages[401]) {
                        setResponseMessage(<p>This is not a valid password reset link. Try requesting to change your password again to get another link. <a onClick={() => { navigate(PASSWORD_RESET_REQUEST); setResponseMessage(undefined); }}>Request Reset</a></p>)
                    } else if (response.error.message === errorMessages[406]) {
                        setResponseMessage(<p>This password reset link to change your password has expired. Try requesting to change your password again to get another link. <a onClick={() => { navigate(PASSWORD_RESET_REQUEST); setResponseMessage(undefined); }}>Request Reset</a></p>)
                    } else if (response.error.message === errorMessages[404]) {
                        setResponseMessage(<p>There is no longer an email associated with this password reset link.</p>)
                    }
                    initStates();
                })
        } else {
            errors = errors.concat('Your new passwords do not match.')
        }
        setSubmitErrors(errors)
    }

    const passwordResetForm = () => {
        return (
            <Card className="resetForm">
                <Form>
                    <Form.Item>
                        <Text strong>
                            Enter in your new password.
                        </Text>
                    </Form.Item>
                    <Form.Item
                        label={"New Password"}
                        required
                    >
                        <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </Form.Item>
                    <p className="p">Your new password needs to have the following:</p>
                    <ul>
                        <li className="li">A minimum of {minLength} characters</li>
                        <li className="li">A minimum of {minLower} lowercase characters</li>
                        <li className="li">A minimum of {minUpper} uppercase characters</li>
                        <li className="li">A minimum of {minNum} numbers</li>
                        <li className="li">
                            A minimum of {minSpecial} special characters
                            <ul>
                                <li className="li">{`! # $ % & ( ) * + , - . < = > ? @ ^ _ | ~`}</li>
                            </ul>
                        </li>

                    </ul>
                    <Form.Item
                        label="Repeat New Password"
                        required
                    >
                        <Input.Password value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            block
                            disabled={newPassword.length === 0 || repeatPassword.length === 0}
                            onClick={submitNewPassword}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                {
                    submitErrors.length > 0 &&
                    <div>
                        <Divider />
                        <ul>
                            {submitErrors.map((e) => {
                                return <li key={e} className="li error">{e}</li>
                            })}
                        </ul>
                    </div>
                }
                {
                    responseMessage === undefined ? '' :
                        <div>
                            <Divider />
                            {
                                responseMessage
                            }
                        </div>
                }
            </Card>
        )
    }

    return (
        <div>
            {
                token.token === undefined ?
                    <Card className="emailForm">
                        <Title level={2}><center>Forgot Password</center></Title>
                        <Form className="password-form" form={form}>
                            <Form.Item>
                                <Text>Enter in your email to get a link to reset your password.</Text>
                                <Input placeholder="example@email.com" value={email} onChange={e => setEmail(e.target.value)} />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    block
                                    disabled={!validEmailSyntax(email) || retryTimer > 0}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        {
                            retryTimer > 0 ? <p>You can try sending another request in <b>{retryTimer}</b> seconds.</p> : ''
                        }
                        {
                            responseMessage === undefined ? '' :
                                <div>
                                    {
                                        responseMessage
                                    }
                                </div>
                        }
                    </Card> : passwordResetForm()
            }
        </div>
    )
}
